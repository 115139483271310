import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, IonIcon, IonToast, IonDatetime, IonLoading, useIonAlert } from '@ionic/react';
//import { SortList } from './MaterialIcons';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';
import { format } from 'date-fns';
import { calendarNumber, list, arrowBack, addCircle, camera } from 'ionicons/icons';
import axios from 'axios'
import Entry from './Entry';
import Util from './Util';

const TipzBrowseList = ({userData, showEditModal, hidden}) => {
  const sortTypes = useMemo(() => ['Date', 'Net Sales', 'Tips'], []);
  const testEntriesToAdd = 5;

  const [ionAlert] = useIonAlert();

  const contentRef = useRef(null);

  const [sortDescending, setSortDescending] = useState(true);
  const [sortType, setSortType] = useState(sortTypes[0]);
  const [sortedEntries, setSortedEntries] = useState([]);
  const [inputSortDescending, setInputSortDescending] = useState('Descending');

  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const [toastTimeout, setToastTimeout] = useState(null);

  const [isAddingEntry, setIsAddingEntry] = useState(false);

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout !== null) {
      clearTimeout(toastTimeout);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
      //setToastTimeout(null);
    }, duration && typeof duration === 'number' ? duration : 2000);
    setToastTimeout(timeout);
  }, [toastTimeout]);

  const onAddTestEntries = useCallback(() => {
    //generate entry with semi-random data for a day in the past that has no entry
    const datestringToEntries = {};
    for(const entry of userData.entries) {
      const datestring = format(new Date(parseInt(entry.startTimestring)), 'MMM d, yy');
      if(typeof datestringToEntries[datestring] !== 'object') {
        datestringToEntries[datestring] = [];
      }
      datestringToEntries[datestring].push(entry);
    }

    const endDate = new Date();
    endDate.setTime(endDate.getTime()-(1000*60*60*24));
    endDate.setHours(Math.floor((Math.random()*10)+9));
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    //check it an entry already exists for this day and keep moving back one day until there is no entry for that day
    let datestring = format(endDate, 'MMM d, yy');
    while(datestringToEntries[datestring] || endDate.getDay() === 0 || endDate.getDay() === 6) { //not on Sun/Sat or day with entries
      endDate.setTime(endDate.getTime()-(1000*60*60*24));
      datestring = format(endDate, 'MMM d, yy');
    }
    datestringToEntries[datestring] = 'exists';

    const startDate = new Date();
    startDate.setTime(endDate.getTime()-(Math.floor(Math.random()*(1000*60*60*7))+(1000*60*60*2)));

    const breakDuration = Math.floor((Math.random()*30)+30);
    const cashTips = Math.floor((Math.random()*50)+50);
    const creditTips = Math.floor((Math.random()*50)+50);
    const tipIn = Math.floor((Math.random()*20)+10);
    const tipOut = parseFloat(((cashTips+creditTips)*0.1).toFixed(2));
    const netSales = Math.floor((Math.random()*300)+200);
    const entry = {
      'id': -1,
      'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[Math.floor(Math.random()*userData.resteraunts.length)],
      'job': userData.jobs.length === 0 ? '' : userData.jobs[Math.floor(Math.random()*userData.jobs.length)],
      'startTimestring': startDate.getTime(),
      'endTimestring': endDate.getTime(),
      'breakDuration': ''+breakDuration,
      'hoursWorkedString': Util.getTimeWorkedString(startDate.getTime(), endDate.getTime(), breakDuration),
      'cashTips': ''+cashTips,
      'creditTips': ''+creditTips,
      'tipIn': ''+tipIn,
      'tipOut': ''+tipOut,
      'netSales': ''+netSales,
      'notes': 'test entry'
    };

    setIsAddingEntry(true);
    axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
      'action': 'addEntry',
      'email': userData.email,
      'sessionKey': userData.sessionKey,
      'entry': JSON.stringify(entry)
    }).then((response) => {
      try {
        if (response.status !== 200) {
          throw "non 200 http response code";
        }
        if ('status' in response.data !== true 
          || 'data' in response.data !== true) {
          throw 'bad response object';
        }
        if(response.data.status === 'error' 
          && typeof response.data.data === 'object'
          && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
          && response.data.data.clientMessage.length > 0) {
          throw { 'modalMessage' : response.data.data.clientMessage };
        }

        //user has an outdated or incorrect session key, make them login again
        if(response.data.status === 'success' 
          && 'action' in response.data.data === true
          && response.data.data.action === 'badSessionKey') {
          setIsAddingEntry(false);
          ionAlert({
            header: 'Session Expired',
            message: 'You session has expired, you\'ll need to login again to continue.',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  userData.logout(); 
                }
              }
            ]
          });
          return;
        }

        if(response.data.status !== 'success' 
          || 'action' in response.data.data !== true
          || response.data.data.action !== 'addEntrySuccess'
          || 'entry' in response.data.data !== true
          || typeof response.data.data.entry !== 'string'
          || 'actionId' in response.data.data !== true) {
          throw 'non success response from server';
        }

        console.log('action id', response.data.data.actionId, typeof response.data.data.actionId);
        const entryFromServer = JSON.parse(response.data.data.entry);

        //success
        setIsAddingEntry(false);
        userData.saveNewEntry(entryFromServer);
        showToastMessage('Test Entry Added for ' + Entry.getEntryDatestring(entryFromServer), 'success', 3000);
      } catch(error) {
        if(typeof error === 'object' && 'modalMessage' in error) {
          //showAlert(error.modalMessage);
        } else {
          //showAlert('Something went wrong when adding your entry. Please try again. Reload the page and try again if the problem persists.');
        }
        setIsAddingEntry(false);
        //logger.addError('error doing stuff ' + error)
      }
    }).catch((error) => {
      //showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
      setIsAddingEntry(false);
    });
  }, [userData, showToastMessage, ionAlert]);

  const updateSort = useCallback(() => {
    const newSortedEntries = [];
    for(const entry of userData.entries) {
      newSortedEntries.push(entry);
    }
    newSortedEntries.sort((one, two) => {
      if(sortType === 'Date') {
        return sortDescending ? Entry.getStartTimestamp(two) - Entry.getStartTimestamp(one) : Entry.getStartTimestamp(one) - Entry.getStartTimestamp(two);
      } else if(sortType === 'Net Sales') {
        return sortDescending ? Entry.getNetSales(two) - Entry.getNetSales(one) : Entry.getNetSales(one) - Entry.getNetSales(two);
      } else if(sortType === 'Tips') {
        return sortDescending ? Entry.getTotalTips(two) - Entry.getTotalTips(one) : Entry.getTotalTips(one) - Entry.getTotalTips(two);
      }
      return parseInt(two.startTimestring) - parseInt(one.startTimestring); //default sort by start time descending
    });

    setSortedEntries(newSortedEntries);
  }, [userData.entries, sortType, sortDescending]);

  const getHeader = useCallback(() => {
    console.log('render browse header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Entries ({sortedEntries.length})</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, [sortedEntries]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render browse content');
    return (
      <div className='max-w-screen-sm mx-auto h-full flex flex-col'>
        {/*<div className='flex'><SortList className='mr-2 h-[30px]' />Sort</div>*/}
        <div className='flex flex-col grow-[0]'>
          <div className='m-1'>
            <TipzSelect 
              label='Sort By' 
              items={sortTypes} 
              error='' 
              inlineLabel={true} 
              placeholder={sortTypes[0]} 
              inputValue={sortType}
              onInput={(value) => {
                setSortType(value); 
                // scroll list of entries to the top
                if(contentRef.current) {
                  contentRef.current.scrollTo(0, 0);
                }
              }} 
            /> 
          </div>
          <IonSegment 
            className='w-full'
            value={inputSortDescending}
            onIonChange={(event) => {
              setInputSortDescending(event.target.value);
              setSortDescending(event.target.value === 'Descending');

              // scroll list of entries to the top
              if(contentRef.current) {
                contentRef.current.scrollTo(0, 0);
              }
            }}
          >
            <IonSegmentButton value='Descending'>
              <IonLabel>Descending</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='Ascending'>
              <IonLabel>Ascending</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
        <div ref={contentRef} className='grow-[1] overflow-y-scroll h-full'>
          {sortedEntries.length > 0 ?
            <IonList>
              {sortedEntries.map((entry, key) => (
                <IonItem key={key} className='cursor-pointer px-2' lines='full' onClick={() => {
                  showEditModal(true, entry);
                }}>
                  {Entry.getListLabel(entry)}
                </IonItem>
              ))}
            </IonList>
          :
            <h2 className='text-center w-full'>No Entries</h2>
          }
          {window.location.origin.match(/.*localhost.*/) !== null &&
            <div className='text-center my-5'>
              <IonButton onClick={onAddTestEntries}>
                <IonIcon icon={addCircle} className='mr-2 text-2xl align-middle'/>Add Test Entry
              </IonButton>
            </div>
          }
        </div>
      </div>
    );
  }, [inputSortDescending, onAddTestEntries, showEditModal, sortedEntries, sortType, sortTypes]);

  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  useEffect(() => {
    updateSort(sortType, sortDescending);
  }, [updateSort, sortType, sortDescending]);

  useEffect(() => {
    if(hidden) {
      setToastShowing(false);
    } else if(contentRef.current) {
      // scroll list of entries to the top
      contentRef.current.scrollTo(0, 0);
    }
  }, [hidden]);

  const elementJSX = useMemo(() => (
    <>
      <IonHeader className={hidden === true ? 'hidden' : ''}>
        {headerJSX}
      </IonHeader>
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
      <IonLoading message='Adding Entry' isOpen={isAddingEntry} />
      <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
    </>
  ), [headerJSX, contentJSX, hidden, toastMessage, toastColor, toastShowing, isAddingEntry]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzBrowseList;


//<IonButtons>
//  <IonBackButton routerLink='/' defaultHref='/' className=''></IonBackButton>
//</IonButtons>

//routerDirection='forward' routerLink={'/editEntry/'+entry.id} 


//set document title for page
//useEffect(() => {
//  if(router.routeInfo.pathname === '/browseEntries') {
//    document.title = 'Browse Entries | Tipz';
//  }
//});

//restore scroll position when location changes
//useEffect(() => {
//  setTimeout(() => {
//    if(contentRef.current) {
//      contentRef.current.scrollToPoint(0, contentScrollY)
//    }
//  }, 1);
//}, [location]);


//save scroll position
//contentRef.current.getScrollElement().then(data => {
//  setContentScrollY(data.scrollTop);
//});
