import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';

const LoadingScreen = () => {
  return (
    <div className='h-screen w-screen relative loadingBackground'>
      <div className='absolute left-1/2 translate-x-[-50%] top-[45%] translate-y-[-50%]'>
        <TipzLogoMinimal className='h-[100px]' />
      </div>
    </div>
  )
};

export default LoadingScreen;
