import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton, IonIcon, IonToast, IonModal } from '@ionic/react';
//import { SortList } from './MaterialIcons';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogo from './TipzLogo';
import TipzLogoMinimal from './TipzLogoMinimal';
import { formatISO, format } from 'date-fns';
import { chevronBack, chevronForward, calendarNumber, list, arrowBack, addCircle, camera } from 'ionicons/icons';
import Entry from './Entry';
import Util from './Util';

const TipzBrowseCalendar = ({userData, showEditModal, showNewEntryModal, hidden}) => {
  const [toastMessage, setToastMessage] = useState('');
  const [toastShowing, setToastShowing] = useState(false);
  const [toastColor, setToastColor] = useState('');
  const [toastTimeout, setToastTimeout] = useState(null);

  const [selectedCalendarDate, setSelectedCalendarDate] = useState(new Date());
  const [tipsForDatestring, setTipsForDatestring] = useState(null);
  const [entriesForDatestring, setEntriesForDatestring] = useState(null);
  const [entrySelectionModalOpen, setEntrySelectionModalOpen] = useState(false);
  const [entrySelectionsForModal, setEntrySelectionsForModal] = useState([]);

  const calendarRef = useRef(null);
  const dragX = useRef(null);
  const dragStartX = useRef(null);
  const dragStartTime = useRef(null);

  const showToastMessage = useCallback((message, color, duration) => {
    //show toast then hide after a delay period
    setToastMessage(message);
    if(color) {
      setToastColor(color);
    }
    setToastShowing(true);

    if(toastTimeout !== null) {
      clearTimeout(toastTimeout);
    } 
    const timeout = setTimeout(() => {
      setToastShowing(false);
      //setToastTimeout(null);
    }, duration && typeof duration === 'number' ? duration : 2000);
    setToastTimeout(timeout);
  }, [toastTimeout]);

  function getMouseCoords(event) {
    var coords = [];
    if (event.type.indexOf('touch') >= 0) {
      if (event.touches && event.touches.length) { 	// iPhone
        coords[0] = event.touches[0].clientX;
        coords[1] = event.touches[0].clientY;
      } else { 								// all others
        coords[0] = event.clientX;
        coords[1] = event.clientY;
      }
    } else {
      if (event.pageX == null) { // IE case
        var doc = (document.documentElement && document.documentElement.scrollLeft != null) ? document.documentElement : document.body;
        coords[0] = event.clientX + doc.scrollLeft;
        coords[1] = event.clientY + doc.scrollTop;
      } else { // all other browsers
        coords[0] = event.pageX;
        coords[1] = event.pageY;
      }
    }
    return coords;
  }

  useEffect(() => {
    const newTipsForDatestring = {};
    const newEntriesForDatestring = {};

    for(const entry of userData.entries) {
      const date = new Date(parseInt(entry.startTimestring));
      const datestring = format(date, 'MMM d, yyyy');
      const totalTips = Entry.getTotalTips(entry); 
      if(typeof newTipsForDatestring[datestring] !== 'number') {
        newTipsForDatestring[datestring] = totalTips;
      } else {
        newTipsForDatestring[datestring] += totalTips;
      }

      if(typeof newEntriesForDatestring[datestring] !== 'object') {
        newEntriesForDatestring[datestring] = [];
      } 
      newEntriesForDatestring[datestring].push(entry);
    }

    setTipsForDatestring(newTipsForDatestring);
    setEntriesForDatestring(newEntriesForDatestring);
  }, [userData]);

  useEffect(() => {
    if(hidden) {
      setToastShowing(false);
      const newCurrentCalendarDate = new Date();
      setSelectedCalendarDate(newCurrentCalendarDate);
      setEntrySelectionModalOpen(false);
      setEntrySelectionsForModal([]);
    }
  }, [hidden]);

  useEffect(() => {
    const startFling = (e) => {
      dragX.current = dragStartX.current = getMouseCoords(e)[0];
      dragStartTime.current = new Date().getTime();
    };

    const moveFling = (e) => {
      dragX.current = getMouseCoords(e)[0];
    };

    const endFling = (e) => {
      if(Math.abs(dragStartX.current - dragX.current) > 30 && (new Date().getTime() - dragStartTime.current) < 500) {
        if(dragX.current > dragStartX.current) {
          const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
          newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
          newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() - 1);
          setSelectedCalendarDate(newCurrentCalendarDate);
        } else {
          const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
          newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
          newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() + 1);
          setSelectedCalendarDate(newCurrentCalendarDate);
          //if(calendarRef.current !== null) {
          //  calendarRef.current.focus();
          //}
        }
      }
    };

    const savedCalendarRef = calendarRef.current;

    if(calendarRef.current !== null) {
      calendarRef.current.addEventListener('mousedown', startFling);
      calendarRef.current.addEventListener('mousemove', moveFling);
      calendarRef.current.addEventListener('mouseup', endFling);
      
      calendarRef.current.addEventListener('touchstart', startFling);
      calendarRef.current.addEventListener('touchmove', moveFling);
      calendarRef.current.addEventListener('touchend', endFling);
    }
    return () => {
      if(savedCalendarRef !== null) {
        savedCalendarRef.removeEventListener('mousedown', startFling);
        savedCalendarRef.removeEventListener('mousemove', moveFling);
        savedCalendarRef.removeEventListener('mouseup', endFling);

        savedCalendarRef.removeEventListener('touchstart', startFling);
        savedCalendarRef.removeEventListener('touchmove', moveFling);
        savedCalendarRef.removeEventListener('touchend', endFling);
      }
    };
  }, [selectedCalendarDate]);

  const getHeader = useCallback(() => {
    console.log('render calendar header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Entries ({userData.entries.length})</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, [userData.entries]);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getCalendar = useCallback(() => {
    const currentDatestring = format(new Date(), 'MMM d, yyyy');
    const selectedDate = new Date(selectedCalendarDate.getTime());
    const lastDayPreviousMonth = new Date(selectedCalendarDate.getTime());
    const startDate = new Date(selectedCalendarDate.getTime());
    startDate.setDate(1); //set to first day of month
    startDate.setDate(startDate.getDate() - startDate.getDay()); //set to first day of week
    const endDate = new Date(selectedCalendarDate.getTime());
    endDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
    endDate.setMonth(endDate.getMonth() + 1); //set to next month
    endDate.setDate(0); //set to last day of month previous month (last day of this month)
    endDate.setDate(endDate.getDate() + (6 - endDate.getDay())); //set to last day of week
    lastDayPreviousMonth.setDate(0); //set to last day of previous month
    const lastDayPreviousMonthDatestring = format(new Date(lastDayPreviousMonth.getTime()), 'MMM d, yyyy');
    const days = [];

    const date = new Date(startDate.getTime());
    while(date.getTime() <= endDate.getTime()) {
      const datestring = format(date, 'MMM d, yyyy');
      if(date.getMonth() === selectedDate.getMonth()) {
        const tipsForDay = tipsForDatestring !== null && typeof tipsForDatestring[datestring] === 'number' ? tipsForDatestring[datestring] : '';
        const entriesForDay = entriesForDatestring !== null && typeof entriesForDatestring[datestring] === 'object' ? entriesForDatestring[datestring] : null;
        days.push({ 
          'day': date.getDate(),
          'tipTotal': tipsForDay,
          'inMonth': true,
          'isToday': datestring === currentDatestring,
          'isLastDayPreviousMonth': false,
          'entriesForDay': entriesForDay,
          'timestamp': date.getTime()
        });
      } else {
        days.push({ 
          'day': '',
          'tipTotal': '',
          'inMonth': false,
          'isToday': false,
          'isLastDayPreviousMonth': datestring === lastDayPreviousMonthDatestring,
          'entriesForDay': null,
          'timestamp': null
        });
      }
      date.setDate(date.getDate() + 1);
    }

    return (
      <div>
        <div className='flex items-center w-full justify-between'>
          <div className='p-3 pl-3 tipzPrimaryColor ' onClick={() => {
            const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
            newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
            newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() - 1);
            setSelectedCalendarDate(newCurrentCalendarDate);
          }}>
            <IonIcon icon={chevronBack} className='text-2xl align-middle cursor-pointer' />
          </div>
          <div className='font-bold my-2 text-lg '>
            {format(selectedDate, 'MMMM yyyy')}
          </div>
          <div className='p-3 pl-3 tipzPrimaryColor' onClick={() => {
            const newCurrentCalendarDate = new Date(selectedCalendarDate.getTime());
            newCurrentCalendarDate.setDate(1); // set to first day of month before incrementing month. if we're on day 31 and increment to a month with 30 days it will skip a month
            newCurrentCalendarDate.setMonth(newCurrentCalendarDate.getMonth() + 1);
            setSelectedCalendarDate(newCurrentCalendarDate);
          }}>
            <IonIcon icon={chevronForward} className='text-2xl align-middle cursor-pointer pr-3' />
          </div>
        </div>
        <div ref={calendarRef} className='grid grid-cols-7 justify-items-center mx-auto'>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Sun</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Mon</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Tue</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Wed</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Thu</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Fri</span>
          <span className='uppercase text-sm font-bold tipzMonthCalendarLabel'>Sat</span>
          {days.map((dayNumber, index) => (
            <div key={index} className={
                (dayNumber.inMonth ? 
                  ' border-stone-700 dark:border-stone-500 ' 
                  : (index < 7 ? 
                      ' border-stone-200 dark:border-stone-800 border-b-stone-700 dark:border-b-stone-500 ' 
                      : ' border-stone-200 dark:border-stone-800 '))
                + (index < 7 ? ' border-t ' : '') 
                + (index % 7 === 0 ? ' sm:border-l border-r ' : (index % 7 === 6 ? ' sm:border-r ' : ' border-r '))
                + (dayNumber.isLastDayPreviousMonth ? ' border-r-stone-700 dark:border-r-stone-500 ' : '')
                + (dayNumber.inMonth && index < 7 ? ' ' : '')
                + ' border-b border-solid w-full aspect-[0.7] flex flex-col justify-around items-around h-full whitespace-nowrap '
                + (dayNumber.inMonth ? 
                  ' cursor-pointer active:outline-yellow-500 active:outline active:outline-2 active:outline-offset-[-1px] ' 
                  : '')
               } 
               onClick={() => {
                 if(dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) {
                   setEntrySelectionModalOpen(false);
                   setEntrySelectionsForModal(dayNumber.entriesForDay);
                   setTimeout(() => {
                     setEntrySelectionModalOpen(true);
                   }, 10);
                 } else if(dayNumber.inMonth && dayNumber.entriesForDay !== null) {
                   //update existing entry
                   showEditModal(true, dayNumber.entriesForDay[0]);
                   //TODO: ask user to pick which entry to view if there are more than one entry on the selected day
                 } else if(dayNumber.inMonth && dayNumber.entriesForDay === null) {
                   const currentTime = new Date();
                   const newEntryDate = new Date(parseInt(dayNumber.timestamp));
                   //keep the day-month-year but set to the current hours-minutes-seconds
                   newEntryDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());
                   //add new entry for the selected day
                   showNewEntryModal(newEntryDate.getTime());
                 }
               }}
              >
              {dayNumber.isToday &&
                <div className='tipzTodayMarker w-full text-center font-bold text-xs pl-0.5'>Today</div>
              }
              <div className={((dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) ? 'flex' : '') + ' text-left text-lg'}>
               <div className='leading-3 pt-2 pl-1.5'>{dayNumber.day}</div>
               {(dayNumber.entriesForDay !== null && dayNumber.entriesForDay.length > 1) &&
                 <div className='text-sm w-full text-center text-amber-700 font-bold dark:text-amber-400'>({dayNumber.entriesForDay.length})</div>
               }
             </div>
              <div className={'grow font-bold text-center place-content-center active:scale-110'}>
                <span className='inline'>{(typeof dayNumber.tipTotal === 'number' ? '$' : '')}</span>
                <span className='inline tipzMoney '>{(typeof dayNumber.tipTotal === 'number' ? parseInt(Math.round(parseFloat(dayNumber.tipTotal))) : '')}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [tipsForDatestring, entriesForDatestring, selectedCalendarDate, showEditModal, showNewEntryModal]);

  const getContent = useCallback(() => {
    console.log('render calendar content');
    return (
      <div className='max-w-screen-sm mx-auto select-none'>
        {getCalendar()}
      </div>
    );
  }, [getCalendar]);

  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      <IonHeader className={hidden === true ? 'hidden' : ''}>
        {headerJSX}
      </IonHeader>
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
      <IonToast className='' message={toastMessage} color={toastColor} isOpen={toastShowing}></IonToast>
      {/* Modal showing popup entry selection for days with more than one entry */}
      <IonModal
        isOpen={entrySelectionModalOpen}
        initialBreakpoint={0.40}
        breakpoints={[0, 0.40, 0.8]}
        header="Select Entry"
      >
        <IonContent>
          <div className='text font-bold p-1 pt-3 pl-2'>{entrySelectionsForModal.length} Entries for {entrySelectionsForModal.length > 0 ?   format(new Date(parseInt(Entry.getStartTimestamp(entrySelectionsForModal[0]))), 'EEE, MMM d, yyyy') : ''}</div>
          <IonList>
            {entrySelectionsForModal.map((entry, key) => (
              <IonItem key={key} className='cursor-pointer px-2' lines='full' onClick={() => {
                showEditModal(true, entry);
                setEntrySelectionModalOpen(false);
              }}>
                {Entry.getListLabel(entry)}
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
    </>
  ), [headerJSX, contentJSX, hidden, toastMessage, toastColor, toastShowing, entrySelectionModalOpen, entrySelectionsForModal, showEditModal]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzBrowseCalendar;
