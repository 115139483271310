import { IonItem, IonLabel, IonText, IonSelect, IonSelectOption  } from '@ionic/react';

//this component uses styles from sr/index.css
//the styles relevant to this component are enabled by adding the 'tipz' class to IonItem

const TipzSelect = ({label = null, items, error, onInput, headerWarning, placeholder, inlineLabel, inputValue, className = ''}) => {
  const useHeaderWarning = headerWarning || false;

  const getLabelJSX = () => {
    if(label === null) {
      return <></>;
    }
    if(inlineLabel === false && useHeaderWarning) {
      return <IonLabel position='stacked' className='p-0'>{label}{error.length > 0 ? <strong className='ml-4 text-red-400'>{error}</strong> : ''}</IonLabel>;
    }
    if(inlineLabel === false) {
      return <IonLabel position='stacked' className='p-0'>{label}</IonLabel>;
    }
  };

  return (
    <IonItem lines='none' className='tipz w-full h-full'>
      <IonSelect 
        {...(label !== null ? {label: inlineLabel === true ? label : ' '} : '')}
        className={'min-h-[46px]' + (typeof className === 'string' ? ' ' + className : '')}
        interface='action-sheet' 
        fill='outline' 
        placeholder={placeholder} 
        value={inputValue}
        onIonChange={(event) => {
          if(onInput && typeof onInput === 'function') {
            onInput(event.target.value);
          }
      }}>
        {items.map((item, key) => (
          <IonSelectOption key={key}>{item}</IonSelectOption>
        ))}
      </IonSelect>
      {useHeaderWarning === false &&
        <IonText color='danger' className={`${error.length > 0 ? '' : 'hidden'}`}>{error.length > 0 ? error : 'error'}</IonText>
      }
    </IonItem>
  );
}

export default TipzSelect;
