import { format } from 'date-fns';
import Util from './Util';
import { IonIcon, IonLabel } from '@ionic/react';
import { camera } from 'ionicons/icons';

const getListLabel = (entry) => {
  const hoursWorked = getHoursWorked(entry);
  return (
    <IonLabel>
      {/* date @ workplace */}
      <h2>{format(new Date(parseInt(entry.startTimestring)), 'EEE, MMM d, yyyy')} @ {entry.resteraunt}{window.location.origin.match(/.*localhost.*/) !== null ? ', (id: '+entry.id+')' : ''}</h2>
      {/*<p>{entry.job + ' @ ' + entry.resteraunt + ' for ' + entry.hoursWorkedString}</p>*/}
      <p className='pt-1'>
        {/* tips */}
        <span className='text-black dark:text-white mr-[1px]'>$</span><span className='tipzMoney'>{`${Util.getCurrencyString(getTotalTips(entry))}`}</span> tips
        {/* net sales */}
        {!isNaN(parseFloat(entry.netSales)) &&
         <>, <span className='text-black dark:text-white mr-[1px]'>$</span><span className='inline-block'><span className='tipzMoney'>{'' + Util.getCurrencyString(entry.netSales)}</span> net sales</span></>
        }
        {/* hours worked */}
        {hoursWorked > 0 && 
          <>, <span className='tipzPrimaryColor '>{hoursWorked.toFixed(2).replace(/\.00$/, '')}</span> hours</>
        }
        {entryHasImage(entry) && <span>, Image <IonIcon icon={camera} color='primary' className='align-middle' /></span>}
      </p>
    </IonLabel>
  );
};

/**
* Returns the hours worked for the given entry as a float, or 0 if the entry doesn't have any hours worked info
**/
const getHoursWorked = (entry) => {
  if(isUsingStartEndTimes(entry)) {
    let startTime = parseInt(entry.startTimestamp);
    if(isNaN(startTime)) {
      startTime = parseInt(entry.startTimestring);
    }
    if(isNaN(startTime)) {
      return 0;
    }

    let endTime = parseInt(entry.endTimestamp);
    if(isNaN(endTime)) {
      endTime = parseInt(entry.endTimestring);
    }
    if(isNaN(endTime)) {
      return 0;
    }

    let breakDuration = parseInt(entry.breakDuration);
    if(isNaN(breakDuration)) {
      breakDuration = 0;
    }

    let entryWorkedMillis = endTime - startTime;
    entryWorkedMillis -= breakDuration*(1000*60); 
    const entryWorkedHours = entryWorkedMillis/(1000*60*60); //convert from milliseconds to hours
    return entryWorkedHours;
  } 

  // not using start and end times
  const hoursWorked = parseFloat(entry.hoursWorked);
  return isNaN(hoursWorked) ? 0 : hoursWorked;
};

const getEntryDatestring = (entry) => {
  if(typeof entry !== 'object') {
    return '';
  }

  const startTimestamp = getStartTimestamp(entry);
  return format(new Date(startTimestamp), 'EEE, MMM d, yyyy');
};

const getStartTimestamp = (entry) => {
  const startTimestamp = parseInt(entry.startTimestamp);
  if(!isNaN(startTimestamp)) {
    return startTimestamp;
  }

  const startTimestring = parseInt(entry.startTimestring);
  if(!isNaN(startTimestring)) {
    return startTimestring;
  }

  return 0;
};

const getEndTimestamp = (entry) => {
  const endTimestamp = parseInt(entry.endTimestamp);
  if(!isNaN(endTimestamp)) {
    return endTimestamp;
  }

  const endTimestring = parseInt(entry.endTimestring);
  if(!isNaN(endTimestring)) {
    return endTimestring;
  }

  return 0;
};

const entryHasImage = (entry) => {
  if(typeof entry !== 'object' || 'hasImage' in entry !== true) {
    return false;
  }
  return entry['hasImage'] === 'true' || entry['hasImage'] === true;
};

const isUsingStartEndTimes = (entry) => {
  if(typeof entry !== 'object') {
    return false;
  }

  const startTimestamp = getStartTimestamp(entry);
  const endTimestamp = getEndTimestamp(entry);
  return startTimestamp > 0 && endTimestamp > 0;

  //const endTimestamp = parseInt(entry.endTimestamp);
  //const endTimestring = parseInt(entry.endTimestring);
  //return (!isNaN(endTimestamp) && endTimestamp > 0) || (!isNaN(endTimestring) && endTimestring > 0);
};

const getNetSales = (entry) => {
  if(typeof entry !== 'object') {
    return 0;
  }

  const netSales = parseFloat(entry.netSales);

  return isNaN(netSales) ? 0 : netSales;
};

const getTotalTips = (entry) => {
  if(typeof entry !== 'object') {
    return 0;
  }

  let totalTips = 0;
  let uncategorizedTips = parseFloat(entry.uncategorizedTips);
  let creditTips = parseFloat(entry.creditTips);
  let cashTips = parseFloat(entry.cashTips);
  let tipIn = parseFloat(entry.tipIn);
  let tipOut = parseFloat(entry.tipOut);

  // if cash or credit tips exist add both to the total, else add uncategorizedTips
  if(!isNaN(creditTips) || !isNaN(cashTips)) {
    if(!isNaN(creditTips)) {
      totalTips += creditTips;
    }
    if(!isNaN(cashTips)) {
      totalTips += cashTips;
    }
  } else if(!isNaN(uncategorizedTips)) {
      totalTips += uncategorizedTips;
  }

  // add tip in
  if(!isNaN(tipIn)) {
    totalTips += tipIn;
  }

  // subtract tip out
  if(!isNaN(tipOut)) {
    totalTips -= tipOut;
  }
  
  return totalTips;
};

const exports = {
  getTotalTips: getTotalTips,
  isUsingStartEndTimes: isUsingStartEndTimes,
  entryHasImage: entryHasImage,
  getStartTimestamp: getStartTimestamp,
  getEndTimestamp: getEndTimestamp,
  getHoursWorked: getHoursWorked,
  getEntryDatestring: getEntryDatestring,
  getListLabel: getListLabel,
  getNetSales: getNetSales,
};

export default exports;
