const TipzLogo = ({className, darkMode = false}) => {
  return (
  <>
    {darkMode === true ?
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 7.804 5.606"
        className={'tipzLogo fill-[#fff] '+className}
      >
        <defs>
          <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
          <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
          <path d="M2046.704 -3246.224H2368.71V-3184.482H2046.704z"></path>
        </defs>
        <g
          fill="#ffff00"
          stroke="#000"
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.704"
          transform="rotate(12.117)"
          style={{ lineHeight: "0" }}
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M8.052 3.183v-.787h-.838L8.03.958 7.995.45H5.948v.799h.878l-.844 1.386.023.548z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#ffff00"
          stroke="#000"
          style={{ lineHeight: "0" }}
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.704"
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M1.018 4.469c.24 0 .44-.023.673-.051l.029-.76c-.114.007-.274.018-.354.018-.08 0-.114-.034-.114-.12V2.421h.474l.04-.707h-.508v-.525H.396l-.051.525h-.32v.707H.3v1.323c0 .548.24.725.72.725z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#00b8c1"
          stroke="#000"
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.123"
          transform="scale(1.11351 .89806)"
          style={{ lineHeight: "0" }}
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M3.538 4.23h.02a.557.557 0 00.38.113c.686 0 .85-.492.85-1.332 0-.825-.17-1.235-.8-1.235-.225 0-.42.052-.527.21h-.026l-.05-.153h-.708v3.155h.86zm.39-1.158c0 .395-.006.6-.195.6a.454.454 0 01-.205-.046v-1.06a.383.383 0 01.2-.062c.184 0 .2.215.2.568z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#0f0"
          stroke="#000"
          style={{ lineHeight: "0", InkscapeFontSpecification: "Passion" }}
          strokeWidth="0.051"
          fontFamily="Passion"
          fontSize="1.926"
          letterSpacing="0"
          wordSpacing="0"
        >
          <path d="M1.992.573c0 .377.422.362.422.5 0 .049-.023.072-.073.072a1.08 1.08 0 01-.295-.06l-.021.287c.073.025.144.04.229.05v.139h.254v-.15c.16-.043.245-.174.245-.366 0-.363-.42-.372-.42-.48 0-.05.035-.073.09-.073.064 0 .154.019.262.044l.039-.308a2.098 2.098 0 00-.216-.029V.026h-.254v.18c-.168.043-.262.18-.262.367z"></path>
        </g>
        <path
          strokeWidth="0.022"
          d="M.5 5.09l.009-.127H.166l-.009.126H.26v.394h.143V5.09z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M.671 5.483v-.397H.533v.397zm-.145-.477c0 .043.021.063.077.063.053 0 .074-.02.074-.063 0-.045-.02-.066-.074-.066-.056 0-.077.021-.077.066z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M.865 5.474h.003c.02.014.035.018.062.018.112 0 .14-.08.14-.217 0-.135-.028-.202-.131-.202-.037 0-.07.008-.087.034H.848L.84 5.082H.724v.516h.14zm.063-.19c0 .065 0 .099-.031.099a.074.074 0 01-.034-.008v-.173c.01-.006.02-.01.033-.01.03 0 .032.035.032.093z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M1.604 5.09l.009-.127h-.344l-.008.126h.103v.394h.143V5.09z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M1.773 5.258c0-.043.01-.052.053-.052h.039l.006-.134c-.058 0-.077.005-.103.034h-.004l-.008-.024h-.122v.401h.139z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M1.882 5.373c0 .072.036.118.103.118.052 0 .076-.015.095-.042h.004c.013.032.042.042.079.042.02 0 .03-.001.054-.003l.012-.101c-.025 0-.035-.005-.035-.027 0-.058.004-.122.004-.173 0-.078-.034-.115-.122-.115a.766.766 0 00-.184.027l.02.123a.559.559 0 01.126-.025c.017 0 .024.01.024.024v.02c-.112.01-.18.033-.18.132zm.155.02c-.018 0-.026-.01-.026-.03 0-.03.018-.038.057-.043v.04c0 .023-.01.033-.031.033z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M2.395 5.284c0-.056.008-.09.04-.09.021 0 .051.011.075.021l.015-.123a.25.25 0 00-.107-.02c-.111 0-.164.093-.164.218 0 .122.052.201.161.201a.3.3 0 00.103-.019l-.015-.107a.311.311 0 01-.069.01c-.032 0-.039-.031-.039-.091z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M2.896 5.483l-.081-.201.084-.2h-.146l-.062.16h-.003v-.296H2.55v.537h.136V5.33h.004l.05.154z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M3.062 5.483v-.397h-.139v.397zm-.145-.477c0 .043.02.063.076.063.054 0 .074-.02.074-.063 0-.045-.02-.066-.074-.066-.055 0-.076.021-.076.066z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M3.359 5.072a.151.151 0 00-.105.04h-.003l-.008-.03h-.129v.401h.141v-.27a.068.068 0 01.04-.012c.012 0 .018.008.018.02v.262h.142v-.309c0-.064-.024-.102-.096-.102z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M3.627 5.52c-.01 0-.013-.004-.013-.013v-.003c0-.008.004-.013.013-.013h.086c.011 0 .015.004.015.012v.004c0 .009-.004.013-.015.013zm.033-.448c-.094 0-.159.047-.159.137 0 .066.021.1.06.127-.04.016-.065.038-.065.077 0 .035.017.052.041.065v.003c-.02.011-.032.023-.032.044 0 .069.073.081.153.081.119 0 .184-.036.184-.13 0-.056-.032-.083-.096-.083h-.114c-.01 0-.014-.005-.014-.014 0-.01.005-.014.015-.014h.04c.101 0 .152-.046.152-.134a.075.075 0 00-.027-.061v-.004h.06v-.094zm.007.11c.024 0 .03.016.03.05 0 .032-.006.05-.028.05-.023 0-.03-.018-.03-.051 0-.032.006-.05.028-.05z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M4.21 4.955c-.103 0-.163.067-.163.165 0 .164.183.157.183.217 0 .021-.01.031-.032.031a.47.47 0 01-.128-.026l-.01.125c.047.015.09.024.152.024.108 0 .165-.062.165-.166 0-.158-.182-.162-.182-.209 0-.022.015-.032.04-.032.027 0 .066.009.113.02l.017-.134a.892.892 0 00-.155-.015z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M4.557 5.483v-.397H4.42v.397zm-.144-.477c0 .043.02.063.076.063.053 0 .074-.02.074-.063 0-.045-.02-.066-.074-.066-.056 0-.076.021-.076.066z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M5.055 5.072a.151.151 0 00-.105.04l-.008.007c-.013-.03-.04-.047-.087-.047a.151.151 0 00-.105.04h-.003l-.009-.03H4.61v.401h.14v-.27a.068.068 0 01.04-.012c.013 0 .019.008.019.02v.262h.142v-.27a.068.068 0 01.04-.012c.012 0 .018.008.018.02v.262h.142v-.309c0-.064-.024-.102-.096-.102z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M5.342 5.474h.004c.019.014.035.018.061.018.113 0 .14-.08.14-.217 0-.135-.028-.202-.131-.202-.037 0-.069.008-.086.034h-.005l-.008-.025h-.116v.516h.141zm.064-.19c0 .065-.001.099-.032.099a.074.074 0 01-.034-.008v-.173c.01-.006.02-.01.033-.01.03 0 .033.035.033.093z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M5.592 5.39c0 .075.038.101.11.101h.046l.01-.118c-.02 0-.028-.008-.028-.026v-.4h-.138z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M5.928 5.483v-.397H5.79v.397zm-.144-.477c0 .043.02.063.076.063.053 0 .074-.02.074-.063 0-.045-.02-.066-.074-.066-.056 0-.076.021-.076.066z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M6.114 4.94c-.078 0-.114.041-.114.112v.042h-.036v.103H6v.286h.14v-.286h.06l.007-.103H6.14v-.015c0-.01.004-.016.015-.016.012 0 .034.002.057.005l.011-.123a1.364 1.364 0 00-.108-.005z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M6.368 5.483v-.397H6.23v.397zm-.145-.477c0 .043.021.063.076.063.054 0 .075-.02.075-.063 0-.045-.021-.066-.075-.066-.055 0-.076.021-.076.066z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M6.588 5.192c.029 0 .038.014.038.039v.02h-.073c0-.034.004-.06.035-.06zm.004-.12c-.123 0-.178.074-.178.21 0 .126.061.21.179.21a.45.45 0 00.14-.022l-.011-.104c-.042.01-.074.02-.108.02-.038 0-.058-.02-.058-.064h.19c.003-.034.007-.068.007-.09 0-.097-.04-.16-.161-.16z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          strokeWidth="0.022"
          d="M7.09 5.491c.021 0 .041-.002.056-.003l.013-.101c-.024 0-.031-.006-.031-.027v-.414h-.14v.147c-.018-.015-.039-.021-.069-.021-.086 0-.133.072-.133.207s.032.212.137.212c.04 0 .067-.016.084-.042h.004c.012.034.046.042.079.042zm-.163-.208c0-.058.002-.093.032-.093.013 0 .023.004.033.01v.173a.074.074 0 01-.033.008c-.031 0-.032-.034-.032-.098z"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          fontFamily="Passion"
          fontSize="38.362"
          letterSpacing="0"
          wordSpacing="0"
        ></path>
        <path
          fill="#0f0"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.051"
          d="M1.848 1.772h.938v2.704h-.938z"
        ></path>
      </svg>
    :
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 7.804 5.606"
        className={'tipzLogo '+className}
      >
        <defs>
          <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
          <path d="M2046.704 -3246.224H4069.656V-2612.815H2046.704z"></path>
          <path
            id="rect4-0-7-36-3-7"
            d="M2046.704 -3246.224H2368.71V-3184.482H2046.704z"
          ></path>
        </defs>
        <g
          fill="#ffff00"
          stroke="#000"
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.704"
          transform="rotate(12.117)"
          style={{ lineHeight: "0" }}
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M8.052 3.183v-.787h-.838L8.03.958 7.995.45H5.948v.799h.878l-.844 1.386.023.548z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#ffff00"
          stroke="#000"
          style={{ lineHeight: "0" }}
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.704"
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M1.018 4.469c.24 0 .44-.023.673-.051l.029-.76c-.114.007-.274.018-.354.018-.08 0-.114-.034-.114-.12V2.421h.474l.04-.707h-.508v-.525H.396l-.051.525h-.32v.707H.3v1.323c0 .548.24.725.72.725z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#00b8c1"
          stroke="#000"
          strokeWidth="0.051"
          fontFamily="Noto Sans Armenian"
          fontSize="5.123"
          transform="scale(1.11351 .89806)"
          style={{ lineHeight: "0" }}
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            d="M3.538 4.23h.02a.557.557 0 00.38.113c.686 0 .85-.492.85-1.332 0-.825-.17-1.235-.8-1.235-.225 0-.42.052-.527.21h-.026l-.05-.153h-.708v3.155h.86zm.39-1.158c0 .395-.006.6-.195.6a.454.454 0 01-.205-.046v-1.06a.383.383 0 01.2-.062c.184 0 .2.215.2.568z"
            style={{ InkscapeFontSpecification: "Passion" }}
            fontFamily="Passion"
          ></path>
        </g>
        <g
          fill="#0f0"
          stroke="#000"
          style={{ lineHeight: "0", InkscapeFontSpecification: "Passion" }}
          strokeWidth="0.051"
          fontFamily="Passion"
          fontSize="1.926"
          letterSpacing="0"
          wordSpacing="0"
        >
          <path d="M1.992.573c0 .377.422.362.422.5 0 .049-.023.072-.073.072a1.08 1.08 0 01-.295-.06l-.021.287c.073.025.144.04.229.05v.139h.254v-.15c.16-.043.245-.174.245-.366 0-.363-.42-.372-.42-.48 0-.05.035-.073.09-.073.064 0 .154.019.262.044l.039-.308a2.098 2.098 0 00-.216-.029V.026h-.254v.18c-.168.043-.262.18-.262.367z"></path>
        </g>
        <g
          display="inline"
          fontFamily="Passion"
          fontSize="38.362"
          transform="translate(-44.522 75.72) scale(.02183)"
          style={{
            lineHeight: "1",
            InkscapeFontSpecification: "Passion",
            whiteSpace: "pre",
          }}
          letterSpacing="0"
          wordSpacing="0"
        >
          <path d="M2062.242-3235.16l.383-5.792h-15.728l-.384 5.793h4.719v18.03h6.56v-18.03z"></path>
          <path d="M2070.067-3217.13v-18.182h-6.33v18.183zm-6.636-21.865c0 1.995.959 2.915 3.49 2.915 2.456 0 3.415-.959 3.415-2.915 0-2.033-.96-2.993-3.414-2.993-2.532 0-3.491.96-3.491 2.993z"></path>
          <path d="M2078.929-3217.551h.153c.883.652 1.612.844 2.84.844 5.14 0 6.367-3.683 6.367-9.974 0-6.176-1.266-9.245-5.984-9.245-1.688 0-3.146.383-3.952 1.572h-.191l-.384-1.15h-5.294v23.63h6.445zm2.915-8.67c0 2.954-.038 4.488-1.457 4.488a3.4 3.4 0 01-1.535-.345v-7.94c.46-.27.92-.461 1.496-.461 1.381 0 1.496 1.611 1.496 4.258z"></path>
          <path d="M2112.802-3235.16l.384-5.792h-15.728l-.384 5.793h4.718v18.03h6.56v-18.03z"></path>
          <path d="M2120.513-3227.448c0-1.957.46-2.379 2.455-2.379h1.765l.268-6.138c-2.647 0-3.529.23-4.718 1.573h-.154l-.383-1.112h-5.601v18.375h6.368z"></path>
          <path d="M2125.538-3222.193c0 3.338 1.612 5.448 4.68 5.448 2.379 0 3.491-.73 4.374-1.957h.192c.575 1.496 1.918 1.957 3.606 1.957.959 0 1.342-.078 2.455-.154l.575-4.642c-1.15 0-1.611-.23-1.611-1.227 0-2.647.192-5.563.192-7.941 0-3.568-1.573-5.256-5.601-5.256-2.762 0-5.754.576-8.44 1.266l.96 5.601c2.148-.614 4.45-1.112 5.754-1.112.767 0 1.074.46 1.074 1.074v.92c-5.102.46-8.21 1.496-8.21 6.023zm7.059.96c-.806 0-1.19-.5-1.19-1.42 0-1.343.845-1.726 2.61-1.957v1.88c0 1.036-.461 1.496-1.42 1.496z"></path>
          <path d="M2149.016-3226.26c0-2.57.345-4.104 1.803-4.104.997 0 2.378.499 3.452.96l.69-5.64c-1.457-.614-2.761-.92-4.91-.92-5.063 0-7.48 4.257-7.48 9.973 0 5.601 2.378 9.245 7.365 9.245 1.803 0 3.108-.345 4.719-.882l-.69-4.91c-1.19.268-2.187.422-3.146.422-1.496 0-1.803-1.381-1.803-4.143z"></path>
          <path d="M2171.956-3217.13l-3.721-9.206 3.874-9.168h-6.713l-2.8 7.327h-.154v-13.542h-6.33v24.59h6.253v-7.059h.154l2.34 7.059z"></path>
          <path d="M2179.552-3217.13v-18.182h-6.33v18.183zm-6.637-21.865c0 1.995.959 2.915 3.49 2.915 2.456 0 3.415-.959 3.415-2.915 0-2.033-.959-2.993-3.414-2.993-2.532 0-3.491.96-3.491 2.993z"></path>
          <path d="M2193.17-3235.965c-2.34 0-3.76.921-4.795 1.803h-.154l-.383-1.342h-5.87v18.375h6.445v-12.39c.46-.346 1.151-.538 1.803-.538.576 0 .844.384.844.96v11.968h6.522v-14.156c0-2.953-1.113-4.68-4.412-4.68z"></path>
          <path d="M2205.446-3215.441c-.422 0-.614-.192-.614-.576v-.153c0-.384.192-.614.614-.614h3.95c.5 0 .692.192.692.576v.153c0 .422-.192.614-.691.614zm1.534-20.524c-4.335 0-7.289 2.149-7.289 6.292 0 3.03.96 4.565 2.724 5.83-1.803.73-2.954 1.727-2.954 3.491 0 1.612.768 2.379 1.88 2.993v.153c-.92.499-1.496 1.036-1.496 1.995 0 3.146 3.376 3.721 7.02 3.721 5.447 0 8.44-1.65 8.44-5.946 0-2.57-1.496-3.836-4.412-3.836h-5.217c-.46 0-.652-.192-.652-.614 0-.46.23-.652.69-.652h1.842c4.641 0 6.943-2.11 6.943-6.138 0-1.15-.345-2.033-1.227-2.8v-.192h2.762v-4.297zm.307 5.026c1.113 0 1.381.767 1.381 2.34 0 1.42-.268 2.263-1.304 2.263s-1.381-.844-1.381-2.34c0-1.458.268-2.263 1.304-2.263z"></path>
          <path d="M2232.145-3241.335c-4.718 0-7.48 3.069-7.48 7.557 0 7.519 8.401 7.212 8.401 9.974 0 .959-.46 1.42-1.458 1.42-1.61 0-3.222-.423-5.869-1.19l-.422 5.716c2.11.69 4.105 1.113 6.905 1.113 4.987 0 7.596-2.84 7.596-7.634 0-7.212-8.363-7.404-8.363-9.552 0-.998.69-1.458 1.803-1.458 1.266 0 3.069.383 5.217.882l.767-6.138c-2.033-.383-4.718-.69-7.097-.69z"></path>
          <path d="M2248.066-3217.13v-18.182h-6.33v18.183zm-6.637-21.865c0 1.995.959 2.915 3.49 2.915 2.456 0 3.415-.959 3.415-2.915 0-2.033-.959-2.993-3.414-2.993-2.532 0-3.491.96-3.491 2.993z"></path>
          <path d="M2270.852-3235.965c-2.34 0-3.76.921-4.795 1.803l-.383.346c-.576-1.381-1.803-2.149-3.99-2.149-2.34 0-3.76.921-4.795 1.803h-.154l-.383-1.342h-5.87v18.375h6.445v-12.39c.46-.346 1.15-.538 1.803-.538.575 0 .844.384.844.96v11.968h6.521v-12.39c.46-.346 1.151-.538 1.804-.538.575 0 .843.384.843.96v11.968h6.522v-14.156c0-2.953-1.113-4.68-4.412-4.68z"></path>
          <path d="M2284.01-3217.551h.154c.882.652 1.611.844 2.839.844 5.14 0 6.368-3.683 6.368-9.974 0-6.176-1.266-9.245-5.985-9.245-1.688 0-3.145.383-3.951 1.572h-.192l-.383-1.15h-5.294v23.63h6.444zm2.916-8.67c0 2.954-.038 4.488-1.458 4.488a3.4 3.4 0 01-1.534-.345v-7.94c.46-.27.92-.461 1.496-.461 1.38 0 1.496 1.611 1.496 4.258z"></path>
          <path d="M2295.442-3221.349c0 3.414 1.765 4.604 5.026 4.604h2.11l.498-5.448c-.959 0-1.304-.345-1.304-1.189v-18.337h-6.33z"></path>
          <path d="M2310.864-3217.13v-18.182h-6.33v18.183zm-6.637-21.865c0 1.995.96 2.915 3.49 2.915 2.456 0 3.415-.959 3.415-2.915 0-2.033-.959-2.993-3.414-2.993-2.532 0-3.491.96-3.491 2.993z"></path>
          <path d="M2319.341-3241.988c-3.529 0-5.178 1.88-5.178 5.103v1.918h-1.688v4.757h1.688v13.08h6.406v-13.08h2.762l.307-4.757h-3.146v-.69c0-.461.192-.691.69-.691.576 0 1.574.077 2.61.191l.537-5.6c-1.305-.115-3.3-.23-4.988-.23z"></path>
          <path d="M2331.003-3217.13v-18.182h-6.33v18.183zm-6.636-21.865c0 1.995.959 2.915 3.49 2.915 2.456 0 3.415-.959 3.415-2.915 0-2.033-.959-2.993-3.414-2.993-2.532 0-3.491.96-3.491 2.993z"></path>
          <path d="M2341.093-3230.479c1.304 0 1.726.652 1.726 1.803v.92h-3.338c0-1.572.192-2.723 1.612-2.723zm.153-5.486c-5.6 0-8.133 3.376-8.133 9.59 0 5.793 2.8 9.63 8.171 9.63 2.417 0 4.335-.346 6.445-.998l-.537-4.757c-1.918.46-3.376.883-4.948.883-1.727 0-2.648-.883-2.648-2.877h8.747c.115-1.573.269-3.108.269-4.144 0-4.411-1.803-7.327-7.366-7.327z"></path>
          <path d="M2364.071-3216.745c.96 0 1.88-.116 2.57-.154l.576-4.642c-1.074 0-1.42-.268-1.42-1.227v-18.95h-6.406v6.712c-.805-.69-1.764-.959-3.145-.959-3.952 0-6.1 3.3-6.1 9.476 0 6.176 1.458 9.743 6.253 9.743 1.841 0 3.107-.767 3.874-1.956h.192c.537 1.573 2.11 1.956 3.606 1.956zm-7.48-9.553c0-2.647.115-4.258 1.496-4.258.575 0 1.036.192 1.496.46v7.941a3.395 3.395 0 01-1.535.346c-1.419 0-1.457-1.535-1.457-4.489z"></path>
        </g>
        <path
          fill="#0f0"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.051"
          d="M1.848 1.772h.938v2.704h-.938z"
        ></path>
      </svg>
    }
  </>
  );
};

export default TipzLogo;
