import { useState, useCallback } from 'react';
import { IonApp, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter, IonToggle, IonIcon, IonLoading, useIonAlert } from '@ionic/react';
import { addCircle, moon } from 'ionicons/icons';
import TipzInput from './TipzInput';
import TipzLogo from './TipzLogo';
import axios from 'axios'

const TipzSetup = ({userData}) => {
  const [ionAlert] = useIonAlert();

  const [stepNumber, setStepNumber] = useState(1);
  const [inputUsername, setInputUsername] = useState({'name': userData.username, 'inputError': ''});
  const [inputResteraunts, setInputResteraunts] = useState(userData.resteraunts.length === 0 ? [{'name': '', 'inputError': ''}] : userData.resteraunts.map((item) => {return {'name': item, 'inputError': ''};}));
  const [inputJobs, setInputJobs] = useState(userData.jobs.length === 0 ?  [{'name': '', 'inputError': ''}] : userData.jobs.map((item) => {return {'name': item, 'inputError': ''};}));
  const [darkModeToggleChecked, setDarkModeToggleChecked] = useState(userData.darkModeEnabled);
  const [buttonLastClickedTime, setButtonLastClickedTime] = useState(0);
  const [nextButtonErrorActive, setNextButtonErrorActive] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);

  const showAlert = useCallback((message) => {
    ionAlert({
      header: 'Error',
      message: message,
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }, [ionAlert]);

  const onAddResteraunt = (event) => {
    const newResteraunts = getArrayCopy(inputResteraunts);
    newResteraunts.push({'name': '', 'inputError': ''});
    setInputResteraunts(newResteraunts);
  };

  const onAddJob = (event) => {
    const newJobs = getArrayCopy(inputJobs);
    newJobs.push({'name': '', 'inputError': ''});
    setInputJobs(newJobs);
  };

  const onBackButton = () => {
    if(stepNumber > 1) {
      setStepNumber(stepNumber-1);
    }
  };

  const onNextButton = (event) => {
    const currentTime = new Date().getTime();
    if(currentTime - buttonLastClickedTime < 300) {
      //don't allow the button to be clicked again before the slide animation finishes
      return;
    }
    setButtonLastClickedTime(currentTime);

    if(stepNumber === 1) {
      const usernameError = userData.getUsernameError(inputUsername.name);
      if(usernameError.length > 0 && inputUsername.name.length > 0) {
        setInputUsername({'name': inputUsername.name, 'inputError': usernameError});
        setNextButtonErrorActive(true);
        setTimeout(() => {
            setNextButtonErrorActive(false);
        }, 2000);
      } else {
        setInputUsername({'name': inputUsername.name, 'inputError': ''});
        userData.saveUsername(inputUsername.name);
        setStepNumber(2);
      }
    } else if(stepNumber === 2) {
      let hasErrors = false;
      for(const resteraunt of inputResteraunts) {
        const nameError = userData.getResterauntNameError(resteraunt.name);
        if(nameError.length > 0) {
          hasErrors = true;
          resteraunt.inputError = nameError;
        } 
      }
      if(!hasErrors) {
        setStepNumber(3);
        setTimeout(() => { //wait a moment to do this, if we do it immediately it messes up the transtion animation on slow devices
          userData.saveResteraunts(inputResteraunts.map((item) => item.name));
        }, 100);
      } else {
        //commit to state the errors we set above
        //if we set it to the current list it won't trigger an update, so we provide a copy
        setInputResteraunts(getArrayCopy(inputResteraunts)); 
        setNextButtonErrorActive(true);
        setTimeout(() => {
          setNextButtonErrorActive(false);
        }, 2000);
      }
    } else if(stepNumber === 3) {
      let hasErrors = false;
      for(const job of inputJobs) {
        const nameError = userData.getJobNameError(job.name);
        if(nameError.length > 0) {
          hasErrors = true;
          job.inputError = nameError;
        } 
      }
      if(!hasErrors) {
        //finished with setup
        //window.location.reload();
        //router.push('/', 'forward', 'replace');

        setIsSavingSettings(true);
        axios.post(process.env.REACT_APP_WEBSITE_ACTION_API_URL, {
          'action': 'updateSettings',
          'email': userData.email,
          'sessionKey': userData.sessionKey,
          'settings': JSON.stringify({
            'darkMode': darkModeToggleChecked,
            'username': inputUsername.name,
            'resteraunts': inputResteraunts.map((item) => item.name),
            'jobs': inputJobs.map((item) => item.name),
          })
        }).then((response) => {
          try {
            if (response.status !== 200) {
              throw "non 200 http response code";
            }
            if ('status' in response.data !== true 
              || 'data' in response.data !== true) {
              throw 'bad response object';
            }
            if(response.data.status === 'error' 
              && typeof response.data.data === 'object'
              && 'clientMessage' in response.data.data && typeof response.data.data.clientMessage === 'string'
              && response.data.data.clientMessage.length > 0) {
              throw { 'modalMessage' : response.data.data.clientMessage };
            }

            //user has an outdated or incorrect session key, make them login again
            if(response.data.status === 'success' 
              && 'action' in response.data.data === true
              && response.data.data.action === 'badSessionKey') {
              setIsSavingSettings(false);
              ionAlert({
                header: 'Session Expired',
                message: 'You session has expired, you\'ll need to login again to continue.',
                buttons: [
                  {
                    text: 'Ok',
                    handler: () => {
                      userData.logout(); 
                    }
                  }
                ]
              });
              return;
            }

            if(response.data.status !== 'success' 
              || 'action' in response.data.data !== true
              || response.data.data.action !== 'updateSettingsSuccess'
              || 'settings' in response.data.data !== true
              || typeof response.data.data.settings !== 'string'
              || 'actionId' in response.data.data !== true
              || typeof response.data.data.actionId !== 'string') {
              throw 'non success response from server';
            }

            const settingsFromServer = JSON.parse(response.data.data.settings);
            //success
            setIsSavingSettings(false);
            userData.saveDarkModeEnabled(settingsFromServer.darkMode);
            userData.saveUsername(settingsFromServer.username);
            userData.saveJobs(settingsFromServer.jobs);
            userData.saveResteraunts(settingsFromServer.resteraunts);
          } catch(error) {
            setIsSavingSettings(false);
            if(typeof error === 'object' && 'modalMessage' in error) {
              showAlert(error.modalMessage);
            } else {
              showAlert('Something went wrong updating your settings. Please try again. Reload the page and try again if the problem persists.');
            }
            //logger.addError('error doing stuff ' + error)
          }
        }).catch((error) => {
          setIsSavingSettings(false);
          showAlert('Couldn\'t contact the server. Please try again. Reload the page and try again if the problem persists.');
        });
      } else {
        //commit to state the errors we set above. if we set it to the current list it won't trigger an update, so we provide a copy
        setInputJobs(getArrayCopy(inputJobs));
        setNextButtonErrorActive(true);
        setTimeout(() => {
            setNextButtonErrorActive(false);
        }, 2000);
      }
    }
  };

  //get a shallow copy of an array
  //used when signalling that we've updated the state of an array, because react acts weird when calling setState on the original state object
  const getArrayCopy = (arrayToCopy) => {
    const copy = [];
    for(const item of arrayToCopy) {
      copy.push(item);
    }
    return copy;
  }

  const step1Jsx = () => {
    return (
      <>
        <h1 className='mt-0'>Welcome!</h1>
        {/*<p className='capitalize'>Please enter your name</p>
        <TipzInput label='Name' input={inputUsername.name} error={inputUsername.inputError} onInput={(value) => {
          setInputUsername({'name': value, 'inputError': ''}); //clear error message if typing
        }}/>*/}
        <IonToggle checked={darkModeToggleChecked} onIonChange={(event) => { 
          setDarkModeToggleChecked(event.target.checked);
          userData.saveDarkModeEnabled(event.target.checked);
        }} className='text-lg'>
          <IonIcon icon={moon} className='text-xl align-middle mr-1' /> Dark Mode
        </IonToggle>
      </>
    );
  }

  const step2Jsx = () => {
    return (
      <>
        <p className='capitalize'>Enter your workplace(s) </p>
        {inputResteraunts.map((resteraunt, key) => 
          <TipzInput key={key} label={`Workplace${key > 0 ? ' #'+(key+1) : ''}`} input={resteraunt.name} error={resteraunt.inputError} onInput={(value) => {
            resteraunt.name = value;
            resteraunt.inputError = '';
            setInputResteraunts(getArrayCopy(inputResteraunts));
          }} onDelete={inputResteraunts.length < 2 ? null : (value) => {
            setInputResteraunts(inputResteraunts.filter((item, index) => index !== key)); 
          }}/>
        )}
        <IonButton onClick={onAddResteraunt}>
          <IonIcon icon={addCircle} className='mr-2 text-3xl align-middle'/>Add Another Workplace
        </IonButton>
      </>
    );
  }

  const step3Jsx = () => {
    return (
      <>
        <p className=''>Enter Your Job(s) (e.g. Server)</p>
        {inputJobs.map((job, key) => 
          <TipzInput key={key} label={`Job${key > 0 ? ' #'+(key+1) : ''}`} input={job.name} error={job.inputError} onInput={(value) => {
            job.name = value;
            job.inputError = '';
            setInputJobs(getArrayCopy(inputJobs));
          }} onDelete={inputJobs.length < 2 ? null : (value) => {
            setInputJobs(inputJobs.filter((item, index) => index !== key)); 
          }}/>
        )}
        <IonButton onClick={onAddJob} className=''>
          <IonIcon icon={addCircle} className='mr-2 text-3xl align-middle'/>Add Another Job
        </IonButton>
      </>
    );
  }

  return (
    <IonApp>
      <IonPage className='text-center'>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonTitle>{'Setup (step '+stepNumber+' of 3)'}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className='max-w-screen-sm mx-auto'>
            <div className={`flex flex-col gap-y-2`}>
                <div className='flex justify-center my-4'>
                  <TipzLogo className='h-[100px]' darkMode={userData.darkModeEnabled} />
                </div>
                <div className='relative'>
                  <div className={`absolute flex flex-col gap-y-6 ion-padding w-full transition-all duration-300 
                    ${stepNumber === 1 ? 'translate-x-[0%] opacity-1' : 'translate-x-[-100%] opacity-0 pointer-events-none'}`}
                  >
                    {step1Jsx()}
                  </div>
                  <div className={`absolute flex flex-col gap-y-4 ion-padding w-full transition-all duration-300 
                      ${stepNumber === 1 ? 'translate-x-[100%] opacity-0 pointer-events-none' : ''} 
                      ${stepNumber === 2 ? 'translate-x-[0%] opacity-1' : ''} 
                      ${stepNumber === 3 ? 'translate-x-[-100%] opacity-0 pointer-events-none' : ''}`}
                  >
                    {step2Jsx()}
                  </div>
                  <div className={`absolute flex flex-col gap-y-4 ion-padding w-full transition-all duration-300 
                    ${stepNumber === 3 ? 'translate-x-[0%] opacity-1' : 'translate-x-[100%] opacity-0 pointer-events-none'}`}
                  >
                    {step3Jsx()}
                  </div>
                </div>
            </div>
          </div>
        </IonContent>
        <IonLoading message='Saving Settings' isOpen={isSavingSettings} />
        <IonFooter className='flex'>
          {stepNumber === 1 ?
            <div disabled onClick={onBackButton} className="w-[50%] mx-0.5"></div>
            :
            <IonButton onClick={onBackButton} className="w-[50%]">Back</IonButton>
          }
          <IonButton onClick={onNextButton} color={nextButtonErrorActive ? 'danger' : ''} className="w-[50%]">{`${stepNumber === 3 ? 'Finish' : 'Next'}`}</IonButton>
        </IonFooter>
      </IonPage>
    </IonApp>
  )
};

export default TipzSetup;


//{stepNumber === 1 && step1Jsx()}
//{stepNumber === 2 && step2Jsx()}
//{stepNumber === 3 && step3Jsx()}

//<div className='relative'>
//  <div className={`flex flex-col gap-y-6 ion-padding absolute w-full transition-all duration-300 
//    ${stepNumber === 1 ? 'translate-x-[0%] opacity-1' : 'translate-x-[-100%] opacity-0 pointer-events-none'}
//    `}>
//    {step1Jsx()}
//  </div>
//  <div className={`flex flex-col gap-y-4 ion-padding absolute w-full transition-all duration-300 
//      ${stepNumber === 1 ? 'translate-x-[100%] opacity-0 pointer-events-none' : ''} 
//      ${stepNumber === 2 ? 'translate-x-[0%] opacity-1' : ''} 
//      ${stepNumber === 3 ? 'translate-x-[-100%] opacity-0 pointer-events-none' : ''} 
//    `}>
//    {step2Jsx()}
//  </div>
//  <div className={`flex flex-col gap-y-4 ion-padding absolute w-full transition-all duration-300 
//    ${stepNumber === 3 ? 'translate-x-[0%] opacity-1' : 'translate-x-[100%] opacity-0 pointer-events-none'}
//    `}>
//    {step3Jsx()}
//  </div>
//</div>
