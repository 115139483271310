import { useEffect, useState, useRef, useCallback } from 'react';
import { IonApp, IonIcon, IonFooter, IonPage, IonModal, IonDatetime, IonContent, IonButton, IonLabel, IonFab, IonFabButton } from '@ionic/react';
import TipzSummary from './TipzSummary';
import TipzGoals from './TipzGoals';
import TipzCreateEntry from './TipzCreateEntry';
import TipzBrowseList from './TipzBrowseList';
import TipzBrowseCalendar from './TipzBrowseCalendar';
import TipzSettings from './TipzSettings';
import { download, calendarNumber, add, pieChart, barChart, list, addCircle, settings } from 'ionicons/icons';
import { formatISO, parseISO } from 'date-fns';
import TipzLogo from './TipzLogo';
import { FlagIcon } from './MaterialIcons';

function TipzLoaded({userData}) {
  const [activePage, setActivePage] = useState('menu');

  const [editEntryModalOpen, setEditEntryModalShowing] = useState(false);
  const [editEntry, setEditEntry] = useState(null);
  const [isEditingNewEntry, setIsEditingNewEntry] = useState(false);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [modalTime, setModalTime] = useState(formatISO(new Date()));
  const [modalTimeChangeCallback, setModalTimeChangeCallback] = useState(null);
  const contentRef = useRef(null);

  const dateTimeRef = useRef(null);

  const showDatePickerModal = useCallback((isOpen) => {
    if(isOpen === true) {
      setDatePickerOpen(false);
      setTimeout(() => {
        setDatePickerOpen(true);
      }, 1);
      return;
    } 

    setDatePickerOpen(isOpen);
  }, []);

  const showNewEntryModal = useCallback((startTimestamp) => {
      setEditEntryModalShowing(false);
      setIsEditingNewEntry(true);
      setEditEntry({
       'id': -1,
       'resteraunt': userData.resteraunts.length === 0 ? '' : userData.resteraunts[0],
       'job': userData.jobs.length === 0 ? '' : userData.jobs[0],
       'startTimestring': parseInt(startTimestamp),
       'startTimestamp': parseInt(startTimestamp),
       'endTimestring': 0,
       'endTimestamp': 0,
       'breakDuration': '',
       'hoursWorkedString': '',
       'uncategorizedTips': '',
       'cashTips': '',
       'creditTips': '',
       'tipIn': '',
       'tipOut': '',
       'netSales': '',
       'notes': ''
      });
      setTimeout(() => {
        window.history.pushState('create', '', '#create');
        setEditEntryModalShowing(true);
      }, 1);
  }, [userData]);

  const showEditModal = useCallback((isOpen, entry) => {
    if(isOpen === true) {
      setEditEntryModalShowing(false);
      setEditEntry(entry);
      setIsEditingNewEntry(false);
      setTimeout(() => {
        window.history.pushState('edit entry', '', '#edit-entry');
        setEditEntryModalShowing(true);
      }, 1);
    } else {
      window.history.back();
    }
  }, []);
  
  useEffect(() => {
    const onPopState = (event) => {
      const pages = {
        'settings':'',
        'create':'',
        'summary':'',
        'browse':'',
        'goals':'',
        'calendar':''
      };
      if(event && event.state && (event.state === 'browse' || event.state === 'calendar')) {
        setEditEntryModalShowing(false);
        setEditEntry(null);
      } else if(activePage in pages) {
        setActivePage('menu');
        setDatePickerOpen(false);
      }
      //console.log('pop state', activePage, event);
    };
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    }
  }, [activePage]);

  useEffect(() => {
    console.log('render TipzLoaded');
  });

  useEffect(() => {
    if(contentRef.current) {
      contentRef.current.scrollToPoint(0, 0);
    }
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent ref={contentRef} className={(activePage !== 'menu' ? 'hidden' : '') + ' h-full'}>
          <div className={'flex flex-col gap-y-1 justify-start items-center grow h-full bg-cover ' + (activePage === 'menu' ? '' : 'hidden') }  style={{ backgroundImage: 'url("/images/palm-trees.png")' }}>
            <div className='absolute w-full h-full bg-black opacity-70'></div>
            <div className={'relative tipzLoginDisplay w-full py-1 px-2'}>
              <span>Logged in as</span> <span className='font-bold'>{userData.email}</span>
            </div>
            <div className={'flex flex-col gap-y-0 justify-center items-center mt-1'}>
              <div className='relative'>
                {/*<TipzLogo className='h-[100px]' darkMode={userData.darkModeEnabled} />*/}
                {/*<div className='absolute top-1/2 left-full rotate-[-45deg] font-bold text-[#00bbbb] dark:text-[#ffff00]'>BETA</div>*/}
                <TipzLogo className='h-[100px]' darkMode={true} />
                <div className='absolute top-1/2 left-full rotate-[-45deg] font-bold text-[#ffff00]'>BETA</div>
              </div>
            </div>
            {/*<h2>Hello {userData.username}!</h2>*/}
            <div className='flex flex-col justify-center items-start gap-y-0 mt-2'>
              {/*<div className='text-center w-full'>What would you like to do?</div>*/}
              {/*<IonButton 
                color='success'
                className=''
                onClick={() => {
                  setActivePage('create');
                  window.history.pushState('create', '', '#create');
                }}
              >
                <IonIcon color={activePage === 'create' ? 'primary' : ''} icon={addCircle} className='text-2xl mr-2' /> 
                <IonLabel>New Entry</IonLabel>
              </IonButton>
              */}
              <div className='h-[1.5em]'></div>
              <IonButton 
                className=''
                onClick={() => {
                  setActivePage('browse');
                  window.history.pushState('browse', '', '#browse');
                }}
              >
                <IonIcon icon={list} className='text-2xl mr-2' />
                <IonLabel>List</IonLabel>
              </IonButton>
              <IonButton 
                className=''
                onClick={() => {
                  setActivePage('calendar');
                  window.history.pushState('calendar', '', '#calendar');
                }}
              >
                <IonIcon icon={calendarNumber} className='text-2xl mr-2' />
                <IonLabel>Calendar</IonLabel>
              </IonButton>
              <IonButton 
               className=''
               onClick={() => {
                 setActivePage('summary');
                 window.history.pushState('summary', '', '#summary');
               }}
              >
                <IonIcon icon={barChart} className='text-2xl mr-2' />
                <IonLabel>Totals</IonLabel>
              </IonButton>
              {/*<IonButton
                className='w-full'
                onClick={() => {
                  setActivePage('goals');
                  window.history.pushState('goals', '', '#goals');
                }}
              >
                <FlagIcon className='h-[20px] fill-[#151515] mr-2' />
                <IonLabel>View Progress</IonLabel>
              </IonButton>*/}
              <IonButton 
                fill='outline'
                className=''
                onClick={() => {
                  setActivePage('settings');
                  window.history.pushState('settings', '', '#settings');
                }}
              >
                <IonIcon icon={settings} className='text-2xl mr-2' />
                <IonLabel>Settings</IonLabel>
              </IonButton>
              {/*<IonButton 
                fill='outline'
                className=''
                onClick={() => {
                  setActivePage('export');
                  window.history.pushState('export', '', '#export');
                }}
              >
                <IonIcon icon={download} className='text-2xl mr-2' />
                <IonLabel>Export Data</IonLabel>
              </IonButton>*/}
            </div>
          </div>
          <IonFab slot='fixed' vertical='bottom' horizontal='end'>
            <IonFabButton 
              ariaLabel='new entry'
              color='success' 
              onClick={() => {
                setActivePage('create');
                window.history.pushState('create', '', '#create');
              }}
            >
              <IonIcon icon={add} className='' />
            </IonFabButton>
          </IonFab>
          {/*
          <>
          <IonFab slot='fixed' vertical='top' horizontal='start'>
            <IonFabButton 
              ariaLabel='Adjust Settings & Preferences'
              color='none'
              onClick={() => {
                setActivePage('settings');
                window.history.pushState('settings', '', '#settings');
              }}
            >
              <IonIcon icon={settings} className='' />
            </IonFabButton>
          </IonFab>
          </>
          */}
        </IonContent>
        <IonFooter className={activePage !== 'menu' ? 'hidden' : ''}> 
          <div className='flex justify-center items-center font-bold text-2xl w-full bg-stone-500 h-[70px]'>
            Advertisement
          </div>
        </IonFooter> 
        <TipzSummary userData={userData} hidden={activePage !== 'summary'} />
        <TipzBrowseList
          userData={userData}
          showEditModal={showEditModal}
          hidden={activePage !== 'browse'}
        />
        <TipzBrowseCalendar
          userData={userData}
          showEditModal={showEditModal}
          hidden={activePage !== 'calendar'}
          showNewEntryModal={showNewEntryModal}
        />
        {/*<TipzGoals userData={userData} hidden={activePage !== 'goals'} />*/}
        <TipzCreateEntry 
          isOpen={activePage === 'create'}
          userData={userData}
          setModalTime={setModalTime}
          showDatePicker={showDatePickerModal}
          setModalTimeChangeCallback={setModalTimeChangeCallback}
          hidden={activePage !== 'create'} 
          isNewEntry={true} 
          onFinished={() => {
            setActivePage('menu');
          }}
        />
        <TipzSettings userData={userData} hidden={activePage !== 'settings'} />
        <IonModal 
          keepContentsMounted={true} 
          isOpen={editEntryModalOpen}
          onDidDismiss={() => {
            if(window.location.href.match(/.*#create$/) || window.location.href.match(/.*#edit-entry$/)) {
              //this is called only when the user has closed the modal by clicking the the darkened area around it
              window.history.back();
            }
          }}
        >
          <TipzCreateEntry
            userData={userData}
            setModalTime={setModalTime}
            showDatePicker={showDatePickerModal}
            setModalTimeChangeCallback={setModalTimeChangeCallback}
            isOpen={editEntryModalOpen}
            inputEntry={editEntry}
            hidden={activePage === 'menu'} 
            isNewEntry={isEditingNewEntry} 
            onFinished={() => {
              showEditModal(false);
            }}
          />
        </IonModal>
        <IonModal keepContentsMounted={true} isOpen={datePickerOpen}>
          <div className='flex w-full h-full justify-center items-center' onClick={(event) => {
            //close modal if user clicks on backdrop area
            if(event.target.tagName.match(/^div$/i)) {
              showDatePickerModal(false);
              setModalTimeChangeCallback(null);
              dateTimeRef.current.reset(modalTime);
            }
          }}>
            <IonDatetime 
              ref={dateTimeRef}
              value={modalTime}
              presentation='date-time'
              preferWheel={true}
              showDefaultButtons={true}
              duration={10}
              formatOptions={{
                date: {
                  weekday: 'short',
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric'
                },
                time: {
                  hour: '2-digit',
                  minute: '2-digit'
                }
              }}
              onIonChange={(event) => {
                if(modalTimeChangeCallback && typeof modalTimeChangeCallback.callback === 'function') {
                  modalTimeChangeCallback.callback(parseISO(event.target.value).getTime());
                }
                setModalTimeChangeCallback(null);
                showDatePickerModal(false);
              }}
              onIonCancel={(event) => {
                dateTimeRef.current.reset(modalTime);
                setModalTimeChangeCallback(null);
                showDatePickerModal(false);
              }}
            >
            </IonDatetime>
          </div>
        </IonModal>
      </IonPage>
    </IonApp>
  )
}

export default TipzLoaded;


//        {false && 
//          <IonFooter> 
//            <div className='flex justify-around w-full relative tipzTabs'>
//              <div className='flex justify-around items-center w-full max-w-screen-sm'>
//                <div className='flex flex-col justify-center items-center w-1/4 py-1.5 cursor-pointer' onClick={(e) => {
//                  setActivePage('summary');
//                }}>
//                  <IonIcon color={activePage === 'summary' ? 'primary' : ''} icon={barChart} className='text-2xl' />
//                  <div className={'text-xs ' + (activePage === 'summary' ? 'activeTab' : '')}>Summary</div>
//                </div>
//                <div className='flex flex-col justify-center items-center w-1/4 py-1.5 cursor-pointer' onClick={(e) => {
//                  setActivePage('browse');
//                }}>
//                  <IonIcon color={activePage === 'browse' ? 'primary' : ''} icon={list} className='text-2xl' />
//                  <div className={'text-xs ' + (activePage === 'browse' ? 'activeTab' : '')}>Browse Entries</div>
//                </div>
//                <div className='flex flex-col justify-center items-center w-1/4 py-1.5 cursor-pointer' onClick={(e) => {
//                  setActivePage('create');
//                }}>
//                  <IonIcon color={activePage === 'create' ? 'primary' : ''} icon={addCircle} className='text-2xl' />
//                  <div className={'text-xs ' + (activePage === 'create' ? 'activeTab' : '')}>Create Entry</div>
//                </div>
//                <div className='flex flex-col justify-center items-center w-1/4 py-1.5 cursor-pointer' onClick={(e) => {
//                  setActivePage('settings');
//                }}>
//                  <IonIcon color={activePage === 'settings' ? 'primary' : ''} icon={settings} className='text-2xl' />
//                  <div className={'text-xs ' + (activePage === 'settings' ? 'activeTab' : '')}>Settings</div>
//                </div>
//              </div>
//            </div>
//          </IonFooter> 
//        }
