///* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
///* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css'; // Remove if nothing is visible
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Dark mode CSS */
import '@ionic/react/css/palettes/dark.class.css';

/* Custom Tipz CSS */
import './App.scss';

import { useEffect, createContext } from 'react';
import { setupIonicReact } from '@ionic/react';
import useUserData from './hooks/useUserData';
import TipzSetup from './TipzSetup';
import LoadingScreen from './LoadingScreen';
import TipzLoaded from './TipzLoaded'
import TipzLogin from './TipzLogin'
import { useState } from 'react';

setupIonicReact({
  mode: 'ios',
//  mode: 'md',
});

//export const UserDataContext = createContext();

function App() {
  const userData = useUserData();

  useEffect(() => {
    document.title = 'Tipz';
    window.history.replaceState('menu', '', ' ');
    userData.logout();
  }, []);

  useEffect(() => {
    console.log('app userData change');
  }, [userData]);

  return (
  <>
    {!userData.initialized &&
      <LoadingScreen />
    }
    {userData.initialized && !userData.isLoggedIn &&
      <TipzLogin userData={userData} />
    }
    {userData.initialized && userData.isLoggedIn && userData.needsSetup &&
      <TipzSetup userData={userData} />
    }
    {userData.initialized && userData.isLoggedIn && !userData.needsSetup &&
      <TipzLoaded userData={userData} />
    }
  </>
  )
}

export default App;

//<IonModal keepContentsMounted={true} isOpen={dateTimPickerShowing}>
//  {tipzEdit.getHeader()}
//  {tipzEdit.getContent()}
//</IonModal>

//<div className={'absolute w-screen h-screen bg-black z-[10] transtion-all duration-300 ' + (dateTimPickerShowing ? 'translate-y-[0%]' : 'translate-y-[100%] pointer-events-none')}>
//  <TipzCreateEntry userData={userData} inputEntry={editEntry} showEditModal={setDateTimPickerShowing} />
//</div>

//  const oldApp = () => {
//    return (
//      <IonApp>
//        {/* need to pass userData via a context here. routes only get their props updated when the route changes so userData won't update properly if passed as a prop */}
//        <UserDataContext.Provider value={userData}>
//          <TempDataContext.Provider value={tempData}>
//            <IonReactRouter>
//              <IonRouterOutlet>
//                <TipzRoute 
//                  path='/summary'
//                  target={TipzTabs}
//                  exact
//                />
//                <TipzRoute 
//                  path='/create'
//                  target={TipzTabs}
//                  exact
//                />
//                <TipzRoute 
//                  path='/browse'
//                  target={TipzTabs}
//                  exact
//                />
//                <TipzRoute 
//                  path='/settings'
//                  target={TipzTabs}
//                  exact
//                />
//                <TipzRoute 
//                  path='/setup'
//                  target={TipzSetup}
//                  exact
//                />
//                {/* fallback route, if none of the above routes are matched */}
//                <Route>
//                  <RedirectToRoot />
//                </Route>
//              </IonRouterOutlet>
//            </IonReactRouter>
//          </TempDataContext.Provider>
//        </UserDataContext.Provider>
//      </IonApp>
//    )
//  };
