import  { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { IonContent, IonHeader, IonToolbar, IonIcon, IonTitle, IonLabel, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCheckbox, IonChip } from '@ionic/react';
import { Chart as ChartJS } from 'chart.js/auto';
import TipzSelect from './TipzSelect';
import TipzButton from './TipzButton';
import TipzLogoMinimal from './TipzLogoMinimal';
import TipzDatetimePickerButtons from './TipzDatetimePickerButtons'; 
import { Bar } from 'react-chartjs-2';
import { formatISO, format } from 'date-fns';
import { arrowBack } from 'ionicons/icons';
import Entry from './Entry';
import Util from './Util';

const TipzSummary = ({userData, hidden}) => {
  const dateRangeChoices = useMemo(() => ['This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'All Entries', 'Custom Range'], []);
  const barDurationChoice = useMemo(() => ['Daily', 'Weekly', 'Monthly', 'Yearly'], []);

  const chartRef = useRef(null);

  const [summaryHoursWorked, setSummaryHoursWorked] = useState(0);
  const [summaryEntriesCount, setSummaryEntriesCount] = useState(0);
  const [summaryCashCreditTips, setSummaryCashCreditTips] = useState(0);

  const [chartShowingTips, setChartShowingTips] = useState(true);
  const [chartShowingHours, setChartShowingHours] = useState(false);

  const [inputDateRange, setInputDateRange] = useState(dateRangeChoices[5]); 
  const [inputBarDuration, setInputBarDuration] = useState(barDurationChoice[1]); 
  const [inputFromDate, setInputFromDate] = useState(undefined);
  const [inputToDate, setInputToDate] = useState(undefined);
  const [fromTimestamp, setFromTimestamp] = useState(undefined);
  const [toTimestamp, setToTimestamp] = useState(undefined);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [chartOptions, setChartOptions] = useState({
    scales: {
      y: {
        beginAtZero: true,
        //max: 1,
      },
    },
    aspectRatio: 1.5,
  });

  const updateChart = useCallback((fromDate, toDate) => {
    const oneDayMillis = 1000*60*60*24;
    const startTimestamp = fromDate.getTime();
    const endTimestamp = toDate.getTime()+oneDayMillis;
    const dayCount = Math.round((endTimestamp-startTimestamp)/oneDayMillis);
    const currentDate = new Date();

    const newGraphLabels = []; // label data for chart.js
    const hoursWorkedData = []; // dataset for chart.js
    const tipsData = []; // dataset for chart.js
    let maxBarValue = 0.01;
    let totalHoursWorked = 0;
    let totalEntriesCount = 0;
    let totalTips = 0;

    const entriesInRange = [];
    const startOfFromDate = new Date(fromDate.getTime());
    startOfFromDate.setHours(0, 0, 0); // set to first second of the day
    const endOfToDate = new Date(toDate.getTime());
    endOfToDate.setHours(23, 59, 59); // set to last second of the day
    const startOfFromDateTimestamp = startOfFromDate.getTime();
    const endOfToDateTimestamp = endOfToDate.getTime();
    console.log(startOfFromDate);
    console.log(endOfToDate);
    for(const entry of userData.entries) {
      const entryStartTime = Entry.getStartTimestamp(entry);
      if(entryStartTime >= startOfFromDateTimestamp && entryStartTime <= endOfToDateTimestamp) {
        entriesInRange.push(entry);
      }
    }

    if(inputBarDuration === 'Daily') {
      // create a hash map with a datetime string for each entry
      const labelEntries = {};
      for(const entry of entriesInRange) {
        const date = new Date(parseInt(entry.startTimestring));
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        if(typeof labelEntries[datestring] !== 'object') {
          labelEntries[datestring] = [];
        }
        labelEntries[datestring].push(entry);
      }

      //create day labels and data for each label
      for(let i = 0; i < dayCount; i++) {
        const date = new Date();
        date.setTime(startTimestamp+(i*oneDayMillis));
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        newGraphLabels.push(datestring);

        let dayWorkedHours = 0;
        let tipsForLabel = 0;
        if(typeof labelEntries[datestring] === 'object') {
          for(const entry of labelEntries[datestring]) {
            const entryWorkedHours = Entry.getHoursWorked(entry);
            dayWorkedHours += entryWorkedHours;
            totalHoursWorked += entryWorkedHours;
            totalEntriesCount += 1;
            const totalTipsForEntry = Entry.getTotalTips(entry);
            totalTips += totalTipsForEntry;
            tipsForLabel += totalTipsForEntry;
          }
        }
        if(Math.ceil(dayWorkedHours) > maxBarValue) {
          maxBarValue = Math.ceil(dayWorkedHours);
        }
        hoursWorkedData.push(dayWorkedHours > 0 ? parseFloat(dayWorkedHours.toFixed(2)) : '');
        tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');
      }
    } else if(inputBarDuration === 'Weekly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(parseInt(entry.startTimestring));
        date.setDate(date.getDate() - date.getDay()); //set to first day of week
        const datestring = format(date, date.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy'); 
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || parseInt(entry.startTimestring) < parseInt(earliestEntry.startTimestring)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || parseInt(entry.startTimestring) > parseInt(latestEntry.startTimestring)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const firstDayOfToDateWeek = new Date(toDate.getTime());
        firstDayOfToDateWeek.setDate(firstDayOfToDateWeek.getDate() - firstDayOfToDateWeek.getDay()); //set to first day of week
        const endDatestring = format(firstDayOfToDateWeek, firstDayOfToDateWeek.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy'); 
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        indexDate.setDate(indexDate.getDate() - indexDate.getDay()); //set to first day of week
        let indexDatestring = format(indexDate, indexDate.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');  
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push('Wk '+indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
              entry.added = true;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setDate(indexDate.getDate() + 7); //move forward by one week
          indexDatestring = format(indexDate, indexDate.getYear() === currentDate.getYear() ? 'MMM d' : 'MMM d, yy');
        }
      }
    } else if(inputBarDuration === 'Monthly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      const dateStringFormat = "MMM yy";
      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(parseInt(entry.startTimestring));
        const datestring = format(date, dateStringFormat);
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || parseInt(entry.startTimestring) < parseInt(earliestEntry.startTimestring)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || parseInt(entry.startTimestring) > parseInt(latestEntry.startTimestring)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const endDatestring = format(toDate, dateStringFormat);
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        let indexDatestring = format(fromDate, dateStringFormat); 
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push(indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setMonth(indexDate.getMonth()+1); //this causes the date to wrap around to january if the month is >= 12
          indexDatestring = format(indexDate, dateStringFormat); 
        }
      }
    } else if(inputBarDuration === 'Yearly') {
      const entriesPerBar = {};
      let earliestEntry = null;
      let latestEntry = null;
      // create a hash map with a datetime string for each entry, find earliest and latest entries
      for(const entry of entriesInRange) {
        const date = new Date(parseInt(entry.startTimestring));
        const datestring = format(date, 'yyyy');
        if(typeof entriesPerBar[datestring] !== 'object') {
          entriesPerBar[datestring] = [];
        }
        entriesPerBar[datestring].push(entry);

        if(earliestEntry === null || parseInt(entry.startTimestring) < parseInt(earliestEntry.startTimestring)) {
          earliestEntry = entry;
        }
        if(latestEntry === null || parseInt(entry.startTimestring) > parseInt(latestEntry.startTimestring)) {
          latestEntry = entry;
        }
      }

      if(earliestEntry !== null && fromDate.getTime() <= toDate.getTime()) {
        const endDatestring = format(toDate, 'yyyy');
        
        // Create dataset, equal length arrays of labels and corresponding values
        let indexDate = new Date(fromDate.getTime());
        let indexDatestring = format(fromDate, 'yyyy'); 
        let addedFinalBar = false;
        while(!addedFinalBar) {
          //label
          newGraphLabels.push(indexDatestring);

          let hoursWorkedForLabel = 0;
          let tipsForLabel = 0;
          if(typeof entriesPerBar[indexDatestring] === 'object') {
            for(const entry of entriesPerBar[indexDatestring]) {
              const entryWorkedHours = Entry.getHoursWorked(entry);
              hoursWorkedForLabel += entryWorkedHours;
              totalHoursWorked += entryWorkedHours;
              totalEntriesCount += 1;
              const totalTipsForEntry = Entry.getTotalTips(entry);
              totalTips += totalTipsForEntry;
              tipsForLabel += totalTipsForEntry;
            }
          }
          if(Math.ceil(hoursWorkedForLabel) > maxBarValue) {
            maxBarValue = Math.ceil(hoursWorkedForLabel);
          }

          //label value
          hoursWorkedData.push(hoursWorkedForLabel > 0 ? parseFloat(hoursWorkedForLabel.toFixed(2)) : '');
          tipsData.push(tipsForLabel > 0 ? parseFloat(tipsForLabel.toFixed(2)) : '');

          addedFinalBar = indexDatestring === endDatestring;
          indexDate.setFullYear(indexDate.getFullYear()+1);
          indexDatestring = format(indexDate, 'yyyy'); 
        }
      }
    }

    const dataSets = [];
    if(chartShowingTips) {
      dataSets.push({
        label: 'Tips',
        data: tipsData,
        backgroundColor: userData.darkModeEnabled ? '#00ff00' : '#008800',
        yAxisID: 'yAxis2'
      });
    }
    if(chartShowingHours) {
      dataSets.push({
        label: 'Hours Worked',
        data: hoursWorkedData,
        backgroundColor: userData.darkModeEnabled ? '#00ffff' : '#009999',
        yAxisID: 'yAxis1'
      });
    }
    if(dataSets.length === 0) {
      dataSets.push({}); //keep the chart from dissapearing by giving it one empy element
    }

    setChartData({
      labels: newGraphLabels,
      datasets: dataSets
    });
    setChartOptions({
      plugins: {
        legend: {
          display: false
        },
      },
      scales: {
        ...(chartShowingTips ? {
          yAxis2: {
            position: 'left',
            beginAtZero: true,
            ticks: {
              color: userData.darkModeEnabled ? '#00ff00' : '#008800',
              callback: function(value, index, values) {
                return '$' + value
              }
            }
            //max: totalEntriesCount === 0 ? 10 : maxBarValue,
          },
        } : {}),
        ...(chartShowingHours ? {
          yAxis1: {
            position: 'right',
            beginAtZero: true,
            ticks: {
              color: userData.darkModeEnabled ? '#00ffff' : '#009999',
            }
            //max: totalEntriesCount === 0 ? 10 : maxBarValue,
          }
        }: {}),
      },
      aspectRatio: 1.5,
    });

    setSummaryHoursWorked(Util.getTimestringForHours(totalHoursWorked));
    setSummaryEntriesCount(totalEntriesCount);
    setSummaryCashCreditTips(totalTips);
    setInputFromDate(formatISO(fromDate));
    setInputToDate(formatISO(toDate));
  }, [userData, inputBarDuration, chartShowingTips, chartShowingHours]);

  const setTimestampsForDateRange = useCallback((dateRange) => {
    const newFromDate = new Date();
    const newToDate = new Date();
    if(dateRange === 'This Week') {
      newFromDate.setDate(newFromDate.getDate() - newFromDate.getDay()); //set to start of week
      newToDate.setTime(newFromDate.getTime());
      newToDate.setDate(newToDate.getDate() + 6); //set to last day of week
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'Last Week') {
      newFromDate.setDate(newFromDate.getDate() - newFromDate.getDay() - 7); //set to start of last week
      newToDate.setTime(newFromDate.getTime());
      newToDate.setDate(newToDate.getDate() + 6); //set to last day of week
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'This Month') {
      newFromDate.setDate(1);
      newToDate.setDate(1);
      newToDate.setMonth(newToDate.getMonth()+1);
      newToDate.setDate(0);
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'Last Month') {
      newFromDate.setDate(1);
      newFromDate.setMonth(newFromDate.getMonth()-1);
      newToDate.setDate(0); // last day of last month
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'This Year') {
      newFromDate.setDate(1);
      newFromDate.setMonth(0);
      newToDate.setDate(1);
      newToDate.setMonth(12);
      newToDate.setDate(0);
      setFromTimestamp(newFromDate.getTime());
      setToTimestamp(newToDate.getTime());
    } else if(dateRange === 'All Entries') { 
      if(userData.entries.length > 0) {
        let minTimestamp = Number.MAX_VALUE;
        let maxTimestamp = 0;
        for(const entry of userData.entries) {
          if(parseInt(entry.startTimestring) < minTimestamp) {
            minTimestamp = parseInt(entry.startTimestring);
          }
          if(parseInt(entry.startTimestring) > maxTimestamp) {
            maxTimestamp = parseInt(entry.startTimestring);
          }
        }
        newFromDate.setTime(minTimestamp);
        newToDate.setTime(maxTimestamp);
        setFromTimestamp(newFromDate.getTime());
        setToTimestamp(newToDate.getTime());
      } else {
        setFromTimestamp(new Date().getTime());
        setToTimestamp(new Date().getTime());
      }
    } else if(dateRange === 'Custom Range') { 
      //newFromDate.setTime(fromTimestamp);
      //newToDate.setTime(toTimestamp);
      //setFromTimestamp(newFromDate.getTime());
      //setToTimestamp(newToDate.getTime());
    }
  }, [userData.entries]);

  const startTimeElement = useMemo(() => (
    <div className='flex flex-col justify-center items-center w-full'>
      <TipzDatetimePickerButtons 
        hidden={hidden}
        label=''
        dateOnly={true}
        inputValue={inputFromDate}
        onInit={(timestamp) => {}} 
        onClosed={(timestamp) => {
          const date = new Date();
          date.setTime(parseInt(parseInt(timestamp / 1000)*1000));
          date.setHours(0, 0, 0);
          setFromTimestamp(timestamp);
          setInputDateRange('Custom Range');
        }}
      />
      <div className='leading-6'>Start Date</div>
    </div>
  ), [inputFromDate, hidden]);

  const endTimeElement = useMemo(() => (
    <div className='flex flex-col justify-center items-center w-full'>
      <TipzDatetimePickerButtons label='' dateOnly={true} inputValue={inputToDate}
        hidden={hidden}
        onInit={(timestamp) => {}} 
        onClosed={(timestamp) => {
          const date = new Date();
          date.setTime(parseInt(parseInt(timestamp / 1000)*1000));
          date.setHours(0, 0, 0);
          setToTimestamp(timestamp);
          setInputDateRange('Custom Range');
        }}
      />
      <div className='leading-6'>End Date (inclusive)</div>
    </div>
  ), [inputToDate, hidden]);

  const setInputBarDurationForAllEntries = (entries) => {
    let earliestEntry = null;
    let latestEntry = null;
    for(const entry of userData.entries) {
      if(earliestEntry === null || Entry.getStartTimestamp(entry) <  earliestEntry.startTimestamp) {
        earliestEntry = {
          entry: entry,
          startTimestamp: Entry.getStartTimestamp(entry),
        };
      }
      if(latestEntry === null || Entry.getStartTimestamp(entry) >  latestEntry.startTimestamp) {
        latestEntry = {
          entry: entry,
          startTimestamp: Entry.getStartTimestamp(entry),
        };
      }
    }
    if(earliestEntry === null || latestEntry === null) { // abort if we haven't found both an earliest and latest entry
      return;
    }

    const oneMonthInMillis = (1000*60*60*24*30);
    const oneWeekInMillis = (1000*60*60*24*7);
    const entryTotalRangeInMillis = latestEntry.startTimestamp - earliestEntry.startTimestamp;

    if (entryTotalRangeInMillis < oneWeekInMillis) { // all of the user's entries span less than one week
      setInputBarDuration(barDurationChoice[0]); // set the chart's bar duration to one day
    } else if(entryTotalRangeInMillis < oneMonthInMillis) {
      setInputBarDuration(barDurationChoice[1]); // set the chart's bar duration to one week
    } else { // all of the user's entries span more than on month
      setInputBarDuration(barDurationChoice[2]); // set the chart's bar duration to one month
    }
  }

  useEffect(() => {
    setInputBarDurationForAllEntries(userData.entries);
  }, []);

  useEffect(() => {
    setTimestampsForDateRange(inputDateRange);
  }, [inputDateRange, setTimestampsForDateRange]);

  useEffect(() => {
    if(hidden) {
      return;
    }

    ChartJS.defaults.color = userData.darkModeEnabled ? '#fff' : '#000';
    ChartJS.defaults.borderColor = userData.darkModeEnabled ? '#222' : '#ccc';
    if(fromTimestamp === undefined || toTimestamp === undefined) {
      setTimestampsForDateRange(dateRangeChoices[5]);
    } else {
      const newFromDate = new Date();
      const newToDate = new Date();
      newFromDate.setTime(fromTimestamp);
      newToDate.setTime(toTimestamp);
      updateChart(newFromDate, newToDate);
    }
  }, [fromTimestamp, toTimestamp, updateChart, userData.darkModeEnabled, dateRangeChoices, setTimestampsForDateRange, inputBarDuration, hidden]);

  const getHeader = useCallback(() => {
    console.log('render main header');
    return (
      <IonToolbar color='primary'>
        <div className='flex justify-evenly'>
          <TipzButton 
            onClick={() => {
              window.history.back();
            }}
          >
            <IonIcon icon={arrowBack} className='text-xl align-middle' /> Back
          </TipzButton>
          <div className='grow-[1]'></div>
          <IonTitle className=''>Totals</IonTitle>
          <div className='grow-[1]'></div>
          <div className='mr-2'>
            <TipzLogoMinimal darkMode={false} className='h-[40px]' />
          </div>
        </div>
      </IonToolbar>
    );
  }, []);

  const headerJSX = useMemo(() => {
    return getHeader();
  }, [getHeader]);

  const getContent = useCallback(() => {
    console.log('render main content');
    return (
      <div className='max-w-screen-sm mx-auto'>
        <div className={`flex flex-col gap-y-2 m-1`}>
          <div className='py-4 rounded-lg shadow-large m-1.5 tipzCard'>
            <div className='mx-3 flex flex-col justify-start items-start'>
              {(!isNaN(fromTimestamp) && !isNaN(toTimestamp)) &&
                <div className='mb-3 w-full'>
                  <div className='font-bold w-full leading-5 tipzCardSubtitle'>{format(fromTimestamp, 'MMM d, yyyy')} -- {format(toTimestamp, 'MMM d, yyyy')}</div>
                  <div className='font-bold w-full text-2xl flex w-full items-center gap-x-2'>
                    <div className=''>Totals for</div>
                    <div className=''>
                      <TipzSelect 
                        className='noLabel'
                        items={dateRangeChoices} 
                        error='' 
                        inlineLabel={true} 
                        inputValue={inputDateRange}
                        onInput={(value) => { 
                          setInputDateRange(value);
                          if(value === 'This Week' || value === 'Last Week') {
                            setInputBarDuration(barDurationChoice[0]);
                          } else if(value === 'This Month' || value === 'Last Month') {
                            setInputBarDuration(barDurationChoice[1]);
                          } else if(value === 'This Year') {
                            setInputBarDuration(barDurationChoice[2]);
                          } else if(value === 'All Entries') {
                            setInputBarDurationForAllEntries(userData.entries);
                          }
                      }} /> 
                    </div>
                  </div>
                </div>
              }
              {inputDateRange === 'Custom Range' && 
                <div className='flex mb-5 whitespace-nowrap'>
                  {startTimeElement}
                  {endTimeElement}
                </div>
              }
              <div className='text-left flex justify-center items-center gap-x-2 leading-5'>
                <strong>Entries:</strong>
                <p className='leading-3'>{summaryEntriesCount}</p>
              </div>
              <div>
              </div>
              <div className='text-left flex justify-center items-center gap-x-2 leading-5'>
                <strong>Tips:</strong>
                <div className='flex items-center'>
                  <p>$</p><p className='leading-3 tipzMoney'>{Util.getCurrencyString(summaryCashCreditTips, true)}</p>
                </div>
              </div>
              <div className='text-left flex justify-center items-center gap-x-2 leading-5'>
                <strong>Hours Worked:</strong>
                <p className='leading-3'>{summaryHoursWorked}</p>
              </div>
            </div>
          </div>

          <div className='font-bold w-full text-2xl flex w-full items-center gap-x-2 mx-2'>
            <div className=''>Chart with </div>
            <div className=''>
              <TipzSelect 
                items={barDurationChoice} 
                error='' 
                inlineLabel={true} 
                inputValue={inputBarDuration}
                onInput={(value) => { 
                  setInputBarDuration(value);
              }} /> 
            </div>
            <div> bars</div>
          </div>
          <div className='flex justify-start gap-x-6 mx-2'>
            <div>
              <IonCheckbox 
                {...(chartShowingTips ? {checked:''} : {})} 
                color='success'
                className='tipzMoney'
                onIonChange={(event) => {
                  setChartShowingTips(event.detail.checked);
                }}
               >Show Tips
               </IonCheckbox>
            </div>
            <div>
              <IonCheckbox 
                {...(chartShowingHours ? {checked:''} : {})} 
                color='primary'
                className='tipzHours'
                onIonChange={(event) => {
                  setChartShowingHours(event.detail.checked);
                }}
               >Show Hours Worked
               </IonCheckbox>
            </div>
          </div>
          <Bar ref={chartRef} data={chartData} options={chartOptions} className='mt-1 mx-0' />
        </div>
      </div>
    );
  }, [inputDateRange, summaryEntriesCount, summaryHoursWorked, chartData, chartOptions, dateRangeChoices, endTimeElement, startTimeElement, summaryCashCreditTips, inputBarDuration, barDurationChoice, fromTimestamp, toTimestamp, chartShowingTips, chartShowingHours, userData.entries]);
  
  const contentJSX = useMemo(() => {
    return getContent();
  }, [getContent]);

  const elementJSX = useMemo(() => (
    <>
      <IonHeader className={hidden === true ? 'hidden' : ''}>
        {headerJSX}
      </IonHeader>
      <IonContent className={hidden === true ? 'hidden' : ''}>
        {contentJSX}
      </IonContent>
    </>
  ), [headerJSX, contentJSX, hidden]);

  return (
    <>
      {elementJSX}
    </>
  );
};

export default TipzSummary;

//const noAnimation = {
//  duration: 0,
//  direction: 'none',
//  easing: 'linear',
//  enterAnimation: () => Promise.resolve(),
//  leaveAnimation: () => Promise.resolve()
//};


